<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { globalEventBus } from '@/main'
import { fixVSelectKeyboardForIOS } from '@/utilities'
import SelectLanguage from '@/components/SelectLanguage.vue'
import customizeStyle from '../../../customize.json'

@Component({
  components: {
    SelectLanguage,
  },
})
export default class AppHeader extends Vue {
  // iconUser = filePath.iconAvatar
  primary = customizeStyle.header.primary
  secondary = customizeStyle.header.secondary

  constructor() {
    super()
  }

  mounted() {
    fixVSelectKeyboardForIOS()
  }

  handleBackButtonClick(): void {
    globalEventBus.$emit('navigate-to-previous-screen')
  }

  handleAppNameClick(): void {
    if (this.$route.name !== 'RootView') {
      this.$router.replace({ name: 'RootView' })
    } else {
      globalEventBus.$emit('app-name-click')
    }
  }
}
</script>

<template>
  <div>
    <v-app-bar class="pa-0" height="60" elevate-on-scroll app v-if="$store.getters['layout/showPrimaryHeader']">
      <v-container style="padding-bottom: 8px;" class="background-color-header">
        <div class="d-flex align-center" style="position: relative">
          <div :style="{ width: $store.getters['layout/showBackButton'] ? 'max-content' : '41px' }">
            <p
              style="width: max-content"
              @click="handleBackButtonClick"
              class="back-btn"
              v-ripple
              v-show="$store.getters['layout/showBackButton']"
            >{{ $t('Header.002') }}</p>
          </div>
          <v-spacer />
          <v-spacer />
          <img
            :src="secondary.icon"
            :style="{
              width: secondary.width,
              height: secondary.height,
            }"
            @click="handleAppNameClick"
            alt=""
            class="d-block logo"
          />
          <v-spacer />
          <v-spacer />
          <select-language />
        </div>
      </v-container>
    </v-app-bar>
    <template v-if="$store.getters['layout/showSecondaryHeader']">
      <v-container class="background-color-header">
        <select-language style="margin-top: 5px;"/>
        <div class="avatar">
          <img
            :src="primary.icon"
            :style="{
              width: primary.width,
              height: primary.height,
            }"
            alt=""
            class="avatar__icon"
          />
        </div>
      </v-container>
    </template>
  </div>
</template>
<!-- Global style -->
<style lang="scss">
// override vuetify app-bar background color
header.theme--light.v-sheet {
  background-color: #fafafa;
  transform: translate(-50%, 0) !important;
  left: 50% !important;
  width: 776px;
}

// override vuetify app-bar content padding
.v-toolbar__content {
  padding: 0;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.avatar {
  margin-top: 1.2rem;

  &__icon {
    width: 4.5rem;
    display: block;
    margin: 0 auto 1.25rem auto;
  }

  &__text {
    font-size: 3.2rem;
    text-align: center;
  }
}

.justify-top {
  align-self: flex-start;
}

.back-btn {
  font-size: 1.5rem;
  margin-bottom: 0;
  font-weight: bold;
  color: #000;
}

.v-application .display-2 {
  font-size: 2.5rem !important;
}

ul {
  list-style: none;
  padding-left: 16px !important;
  margin-top: 0.75rem;
}
</style>
