import Vue from 'vue'
import Component from 'vue-class-component'
import { Action, Getter } from 'vuex-class'
import ISaleMaster from '@/models/SaleMaster'
import ITicketMaster from '@/models/TicketMaster'
import IPriceMaster from '@/models/PriceMaster'
import { ICategoryCustom, IOptionGroupCustom, ITicketCustom } from '@/custommodels'
import IGroupMaster from '../models/GroupMaster'
import IRelatedInfoTicket from '../custommodels/IRelatedInfoTicket'
import { IDocomoBikeSharePortMaster, IEnvironment, IGateMaster, IProperties, IRouteSearch, IExternalLinkMaster } from '@/models'
import { IRouteTicketInfo } from '@/custommodels/IRouteSearchCustom'
import { FooterMenuTab, ScreenId, ScreenProcess } from '@/utilities'
import { LanguageCode } from '@/i18n'

@Component
export class StoreMixin extends Vue {
  // Auth Store Mixin
  @Action('auth/logout') protected logout!: () => Promise<any>
  @Action('auth/setUserAuth') protected setUserAuth!: () => Promise<any>
  @Action('auth/updateUserProfile') protected updateUserProfile!: (
    profile: any
  ) => Promise<any>
  @Action('auth/setPermissionDenied') protected setPermissionDenied!: (
    isDenied: boolean
  ) => Promise<any>
  @Action('auth/presetUserSessionID') protected presetUserSessionID!: (
    sessionID: string
  ) => Promise<any>
  @Action('auth/setWithdrawUser') protected setWithdrawUser!: (
    isWithdraw: boolean
  ) => Promise<any>

  @Getter('auth/profile') userProfile: any
  @Getter('auth/isAuthenticated') isUserAuthenticated!: boolean
  @Getter('auth/isPermissionDenied') isUserPermissionDenied!: boolean
  @Getter('auth/isWithdrawUser') isWithdrawUser!: boolean
  @Getter('auth/userSessionID') userSessionID!: string

  // System Store Mixin
  @Action('system/getSystemProperties') protected getSystemProperties!: () => Promise<any>
  @Action('system/getSystemEnvironment') protected getSystemEnvironment!: () => Promise<any>
  @Action('system/getSystemRouteSearch') protected getSystemRouteSearch!: () => Promise<any>

  @Getter('system/isHaveRouterFunction') protected isHaveRouterFunction!: boolean
  @Getter('system/isHavePaymentScreen') protected isHavePaymentScreen!: boolean
  @Getter('system/properties') protected systemProperties!: IProperties
  @Getter('system/environment') protected systemEnvironment!: IEnvironment
  @Getter('system/routeSearch') protected systemRouteSearch!: IRouteSearch

  // Layout Store Mixin
  @Action('layout/showPrimaryHeader') protected showPrimaryHeader!: (options: {
    withBackButton: boolean
    withNotificationMessage: boolean
  }) => Promise<any>
  @Action('layout/showSecondaryHeader') protected showSecondaryHeader!: (options: {
    withBackButton: boolean
    withNotificationMessage: boolean
  }) => Promise<any>
  @Action('layout/toggleLoading') protected toggleLoading!: (isShow?: boolean) => void

  // Database Store Mixin
  @Action('database/getGroupMaster') protected getGroupMaster!: () => Promise<any>
  @Action('database/getCategoryMaster') protected getCategoryMaster!: () => Promise<any>
  @Action('database/getTicketMaster') protected getTicketMaster!: () => Promise<any>
  @Action('database/getPriceMaster') protected getPriceMaster!: () => Promise<any>
  @Action('database/getOptionMaster') protected getOptionMaster!: () => Promise<any>
  @Action('database/getCompanyMaster') protected getCompanyMaster!: () => Promise<any>
  @Action('database/getExternalLinkMaster') protected getExternalLinkMaster!: () => Promise<
    any
  >
  @Action('database/getExternalLowerLinkMaster') protected getExternalLowerLinkMaster!: () => Promise<any>
  @Action('database/getOrdersUnuse') protected getOrdersUnused!: () => Promise<any>
  @Action('database/getOrdersUsing') protected getOrdersUsing!: () => Promise<any>
  @Action('database/getOrdersUsed') protected getOrdersUsed!: () => Promise<any>
  @Action('database/getSaleMaster') protected getSaleMaster!: () => Promise<any>
  @Action('database/getOrders') protected getOrdersMaster!: () => Promise<any>
  @Action('database/getPackOrder') protected getPackOrder!: () => Promise<any>
  @Action('database/getPackOrderUsing') protected getPackOrderUsing!: () => Promise<any>
  @Action('database/getPackOrderUsed') protected getPackOrderUsed!: () => Promise<any>
  @Action('database/getGateInfo') protected getGateInfo!: (gateID: string) => Promise<IGateMaster | null>
  @Action('database/getTicket') protected getTicketFor!: (options: {
    orderID: string
    purchasedTicketID: string
  }) => Promise<any>
  @Action('database/getUserData') protected getUserData!: () => Promise<any>
  @Action('database/setMainDataFetched') setMainDataFetched!: (
    isFetch: boolean
  ) => Promise<any>
  @Action('database/getDocomoBikeSharePortMaster')
  protected getDocomoBikeSharePortMaster!: () => Promise<any>
  @Getter('database/isMainDataFetched') isMainDataFetched!: boolean
  @Getter('database/docomoBikeSharePort') docomoBikeSharePort!: IDocomoBikeSharePortMaster[]

  @Getter('layout/isLoading') protected isLoading!: boolean

  @Action('ticket/setOptionsTicket') setOptionsTicket!: (options: string[]) => Promise<void>
  @Action('ticket/setTicketBeforePayment') setTicketBeforePayment!: (
    ticket: any
  ) => Promise<any>
  @Getter('ticket/options') options!: IOptionGroupCustom[]
  @Getter('ticket/optionsSelected') optionsSelected!: ITicketCustom[]
  @Action('ticket/setQuantityPerson') setQuantityPerson!: (quantitys: object) => Promise<void>
  @Getter('ticket/quantityPerson') quantityPerson!: any
  @Getter('ticket/isNewBuy') isNewBuy!: boolean
  @Getter('ticketPurchased/ticketsPurchased') ticketsPurchased!: object[]
  @Getter('ticketPurchased/currentTicketPurchased') currentTicketPurchased!: any
  @Getter('ticketPurchased/localOptionGroups') localOptionGroups!: IOptionGroupCustom[]
  @Action('ticketPurchased/setOrderIdForRefund') setOrderIdForRefund!: (
    orderId: string
  ) => Promise<any>
  @Action('ticketPurchased/setCurrentTicket') setCurrentMainTicketIds!: (
    orderId: string
  ) => Promise<void>
  @Getter('ticketPurchased/orderQuantity') orderQuantity!: any
  @Getter('ticketPurchased/getSale') getSale!: ISaleMaster
  @Getter('ticketPurchased/saleIdResponse') saleIdResponse!: string
  @Getter('ticketPurchased/currentOrderId') currentOrderId!: string
  @Getter('ticketPurchased/ticketAndRelatedInfor') ticketAndRelatedInfor!: IRelatedInfoTicket
  @Getter('ticketPurchased/getTicketForRefund') getTicketForRefund!: IRelatedInfoTicket
  @Action('ticketPurchased/setTicketIdResponse')
  setTicketIdResponse!: (ticketIdResponse: string) => Promise<void>
  @Action('ticketPurchased/setOrderIdResponse') setSaleIdResponse!: (saleID: string) => Promise<void>
  @Action('ticket/setNewBuyTicket')
  protected setNewBuyTicket!: (ticketId: string) => Promise<void>
  @Action('ticket/setAddBuyTicket')
  protected setAddBuyTicket!: (ticketId: string) => Promise<void>
  @Action('ticket/setRouteSelectedTicketIdList')
  protected setRouteSelectedTicketIdList!: (ticketList: IRouteTicketInfo[]) => Promise<void>
  @Getter('ticket/currentTicket')
  protected currentTicket!: ITicketCustom
  @Action('ticket/setCurrentGroup')
  protected setCurrentGroup!: (group: string) => Promise<void>
  @Action('ticket/setCurrentGroupTicketIdList')
  protected setCurrentGroupTicketIdList!: (tickets: string[]) => Promise<void>
  @Getter('ticket/currentGroup')
  protected currentGroup!: IGroupMaster
  @Getter('ticket/tickets')
  protected tickets!: ITicketCustom[]
  @Getter('ticket/categoriesMasterByMap')
  protected categoriesMasterByMap!: any
  @Getter('ticket/groupMasterByMap')
  protected groupMasterByMap!: any
  @Getter('ticket/routeSelectedTicketList')
  protected routeSelectedTicketList!: ITicketCustom[]
  @Getter('ticket/ticketMasterByMap')
  protected ticketMasterByMap!: {[id: string]:ITicketMaster }
  @Getter('ticket/priceMasterByMap')
  protected priceMasterByMap!: {[id: string]:IPriceMaster }
  // Footer Menu Store Mixin

  @Action('footerMenu/changeCurrentProcessOfTopTab')
  protected changeCurrentProcessOfTopTab!: (newProcess: ScreenId | ScreenProcess | string) => Promise<void>
  @Action('footerMenu/changeCurrentProcessOfMyTicketTab')
  protected changeCurrentProcessOfMyTicketTab!: (newProcess: ScreenId | ScreenProcess | string) => Promise<void>
  @Action('footerMenu/changeCurrentProcessOfRouteSearchTab')
  protected changeCurrentProcessOfRouteSearchTab!: (newProcess: ScreenId | ScreenProcess | string) => Promise<void>
  @Action('footerMenu/changeCurrentExternalParentLink')
  protected changeCurrentExternalParentLink!: (newProcess: IExternalLinkMaster | object) => Promise<void>

  @Action('footerMenu/switchToTopTabWithProcess')
  protected switchToTopTabWithProcess!: (newProcess: ScreenId | ScreenProcess | string) => Promise<void>
  @Action('footerMenu/switchToMyTicketTabWithProcess')
  protected switchToMyTicketTabWithProcess!: (newProcess: ScreenId | ScreenProcess | string) => Promise<void>
  @Action('footerMenu/switchToRouteSearchTabWithProcess')
  protected switchToRouteSearchTabWithProcess!: (newProcess: ScreenId | ScreenProcess | string) => Promise<void>

  @Action('footerMenu/crossToTopTabWithProcess')
  protected crossToTopTabWithProcess!: (newProcess: ScreenId | ScreenProcess | string) => Promise<void>

  @Action('footerMenu/switchToTopTab')
  protected switchToTopTab!: () => Promise<void>
  @Action('footerMenu/switchToMyTicketTab')
  protected switchToMyTicketTab!: () => Promise<void>
  @Action('footerMenu/switchToRouteSearchTab')
  protected switchToRouteSearchTab!: () => Promise<void>

  @Action('footerMenu/restartTopTabToDefaultProcess')
  protected restartTopTabToDefaultProcess!: () => Promise<void>
  @Action('footerMenu/restartMyTicketTabToDefaultProcess')
  protected restartMyTicketTabToDefaultProcess!: () => Promise<void>
  @Action('footerMenu/restartRouteSearchTabToDefaultProcess')
  protected restartRouteSearchTabToDefaultProcess!: () => Promise<void>

  @Action('footerMenu/handleChangeFooterTabProcessOnAPIError')
  protected handleChangeFooterTabProcessOnAPIError!: () => Promise<void>

  @Action('footerMenu/updateRedirectToMyTicketTab')
  protected updateRedirectToMyTicketTab!: (newValue: boolean) => Promise<void>

  @Getter('footerMenu/currentTab')
  protected currentFooterTab!: FooterMenuTab

  @Getter('footerMenu/currentProcessOfTopTab')
  protected currentProcessOfTopTab!: ScreenId | ScreenProcess | string
  @Getter('footerMenu/currentProcessOfMyTicketTab')
  protected currentProcessOfMyTicketTab!: ScreenId | ScreenProcess | string
  @Getter('footerMenu/currentProcessOfRouteSearchTab')
  protected currentProcessOfRouteSearchTab!: ScreenId | ScreenProcess | string
  @Getter('footerMenu/currentExternalParentLink')
  protected currentExternalParentLink!: IExternalLinkMaster

  @Getter('footerMenu/topTabDefaultProcessRenderKey')
  protected topTabDefaultProcessRenderKey!: number
  @Getter('footerMenu/myTicketTabDefaultProcessRenderKey')
  protected myTicketTabDefaultProcessRenderKey!: number
  @Getter('footerMenu/routeSearchTabDefaultProcessRenderKey')
  protected routeSearchTabDefaultProcessRenderKey!: number

  @Getter('footerMenu/purchaseRouteTicketRenderKey')
  protected purchaseRouteTicketRenderKey!: number
  @Getter('footerMenu/purchaseOptionTicketFromTicketManagementRenderKey')
  protected purchaseOptionTicketFromTicketManagementRenderKey!: number


  // Google Map Locale Mixin
  @Action('gmapLocale/restartGoogleMap')
  protected restartGoogleMap!: () => Promise<void>

  @Getter('gmapLocale/googleMapComponentRenderKey')
  protected googleMapComponentRenderKey!: number

  protected async getGateNameByGateID(gateID: string) {
    const gateInfo = await this.getGateInfo(gateID)
    return gateInfo ? gateInfo.name[LanguageCode.Ja] : ''
  }
}
