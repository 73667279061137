import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { IGmapLocaleState, IRootState } from '../utilities'
import { destroyMap } from '@/utilities'

const state: IGmapLocaleState = {
  googleMapComponentRenderKey: 1
}

const mutations: MutationTree<IGmapLocaleState> = {
  CHANGE_GOOGLE_MAP_RENDER_KEY(state) {
    state.googleMapComponentRenderKey += 1
  },
  PURGE_STATE(state) {
    state.googleMapComponentRenderKey = 1
  },
}

const actions: ActionTree<IGmapLocaleState, IRootState> = {
  cleanGmapLocaleState({ commit }) {
    commit('PURGE_STATE')
  },
  restartGoogleMap({ commit }) {
    destroyMap()
    commit('CHANGE_GOOGLE_MAP_RENDER_KEY')
  }
}

const getters: GetterTree<IGmapLocaleState, IRootState> = {
  googleMapComponentRenderKey: (state) => state.googleMapComponentRenderKey
}

const gmapLocaleModule: Module<IGmapLocaleState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default gmapLocaleModule
