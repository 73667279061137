export enum FirebaseErrorCode {
  AccountExistsWithDifferentCredential = 'auth/account-exists-with-different-credential',
  AuthDomainConfigRequired = 'auth/auth-domain-config-required',
  AuthUnauthorizedDomain = 'auth/unauthorized-domain',
  CredentialAlreadyInUse = 'auth/credential-already-in-use',
  OperationNotSupportedInThisEnvironment = 'auth/operation-not-supported-in-this-environment',
  Timeout = 'auth/timeout',
  OperationNotAllowed = 'auth/operation-not-allowed',
  EmailAlreadyInUse = 'auth/email-already-in-use',
  InvalidEmail = 'auth/invalid-email',
  UserDisabled = 'auth/user-disabled',
  UserNotFound = 'auth/user-not-found',
  WrongPassword = 'auth/wrong-password',
  TooManyRequests = 'auth/too-many-requests',
  NetworkRequestFailed = 'auth/network-request-failed',
  WeakPassword = 'auth/weak-password',
  MissingAndroidPkgName = 'auth/missing-android-pkg-name',
  MissingContinueUri = 'auth/missing-continue-uri',
  MissingIosBundleId = 'auth/missing-ios-bundle-id',
  InvalidContinueUri = 'auth/invalid-continue-uri',
  UnauthorizedContinueUri = 'auth/unauthorized-continue-uri',
  RequiresRecentLogin = 'auth/requires-recent-login',
  ERROR = 'ERROR',
}

export enum D10 {
  PasswordUnmatched = 'd10/password-unmatched',
  NameEmpty = 'd10/name-empty',
  ForceLogout = 'd10/force-logout',
  ForceBackkey = 'd10/force-backkey',
  TicketInvalid = 'd10/ticket-invalid',
  OptionCantBuy = 'd10/option-cant-buy',
  TicketNotFound = 'd10/ticket-not-found',
  ExceedPurchaseLimit = 'd10/exceed-purchase-limit',
  TicketNotSelected = 'd10/ticket-not-selected',
  OptionNotSelected = 'd10/option-not-selected',
  OptionOverTicket = 'd10/option-over-ticket',
  CreditNoEmpty = 'd10/credit-no-empty',
  CreditSecurityCodeEmpty = 'd10/credit-securitycode-empty',
  CreditHolderNameEmpty = 'd10/credit-holdername-empty',
  TicketTimeout = 'd10/ticket-timeout',
  PingLost = 'd10/ping-lost',
  CardNoEmpty = 'd10/cardno-empty',
  SecurityCodeEmpty = 'd10/securitycode-empty',
  SelectPaymentMethod = 'd10/select-payment-method',
  NotSelectPaymentFrequency = 'd10/not-selected-payment-frequency',
  RouteSearchDeparturePointError = 'd10/routesearch-departurepoint-error',
  RouteSearchArrivalPointError = 'd10/routesearch-arrivalpoint-error',
  RouteSearchDeparturePointTransitPointError = 'd10/routesearch-departurepoint-transitpoint-error',
  RouteSearchArrivalPointTransitPointError = 'd10/routesearch-arrivalpoint-transitpoint-error',
  RouteSearchTransitPointError = 'd10/routesearch-transitpoint-error',
  RouteSearchDeparturePointArrivalPointError = 'd10/routesearch-departurepoint-arrivalpoint-error',
}

export enum D11 {
  RequestErrorStartLimit = 'Request/ErrorStartLimit',
  RequestErrorTicketID = 'Request/ErrorTicketID',
  RequestErrorIssuedCounts = 'Request/ErrorIssuedCounts',
  RequestErrorTicketingOutsidePeriod = 'Request/ErrorTicketingOutsidePeriod',
  RequestErrorCreditSecurityCodeAuth = 'Request/ErrorCreditSecurityCodeAuth',
  RequestErrorRouteResults = 'Request/ErrorRouteResults',
  RequestErrorCreditExpired='Request/ErrorCreditExpired',
  ERROR_101 = 'Token/101',
  ERROR_102 = 'Token/102',
  ERROR_121 = 'Token/121',
  ERROR_122 = 'Token/122',
  ERROR_902 = 'Token/902',
  ERROR_ANY = 'Token/Any',
  ERROR = 'ERROR',
  RequestTicketDisplayLimit = 'Request/ErrorTicketDisplayLimit'
}

export function getAddQRTicketMessage(error: string): string {
  const NO_ERROR_OCCURRED = ''

  switch (error) {
    case NO_ERROR_OCCURRED: // no error occurred
      return 'Request/TicketAdd'
    case D11.RequestErrorTicketID:
      return 'Request/TicketAddFail'
    case D11.RequestErrorIssuedCounts:
      return 'Request/TicketAddFailCount'
    case D11.RequestErrorTicketingOutsidePeriod:
      return 'Request/TicketingOutsidePeriod'
    default:
      return error
  }
}

export enum D12 {
  DocomoBikeShareReserveSuccess = 'd12/DocomoBikeShareReserveSuccess',
}
