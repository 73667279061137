<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { ScreenId } from '@/utilities'
import { Mixins } from 'vue-property-decorator'
import { ProcessMixin } from '../mixins'

@Component({
  components: {
    [ScreenId.MLO_0000]: () => import('@/views/MLO_0000.vue'),
    [ScreenId.MLO_0100]: () => import('@/views/MLO_0100.vue'),
    [ScreenId.MLO_0200]: () => import('@/views/MLO_0200.vue'),
    [ScreenId.MLO_0210]: () => import('@/views/MLO_0210.vue'),
  },
})
export default class LoginProcess extends Mixins(ProcessMixin) {
  email: string = ''

  constructor() {
    super()
    this.currentScreen = ScreenId.MLO_0000
  }
}
</script>

<template>
  <MLO-0000 @change-screen="changeScreen" v-if="currentScreen === 'MLO-0000'" />
  <MLO-0100 @change-screen="changeScreen" v-else-if="currentScreen === 'MLO-0100'" />
  <MLO-0200
    @change-screen="changeScreen"
    @verify-email="email = $event"
    v-else-if="currentScreen === 'MLO-0200'"
  />
  <MLO-0210 :email="email" @change-screen="changeScreen" v-else />
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
