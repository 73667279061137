import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { ILayoutModuleState, IRootState } from '../utilities'

const state: ILayoutModuleState = {
  showBackButton: false,
  showNotificationMessage: false,
  showPrimaryHeader: false,
  showSecondaryHeader: false,
  isLoading: false,
}

const mutations: MutationTree<ILayoutModuleState> = {
  SHOW_PRIMARY_HEADER(state, { withBackButton, withNotificationMessage }) {
    state.showPrimaryHeader = true
    state.showSecondaryHeader = false
    state.showBackButton = withBackButton
    state.showNotificationMessage = withNotificationMessage
  },
  SHOW_SECONDARY_HEADER(state, { withBackButton, withNotificationMessage }) {
    state.showSecondaryHeader = true
    state.showPrimaryHeader = false
    state.showBackButton = withBackButton
    state.showNotificationMessage = withNotificationMessage
  },
  TOGGLE_LOADING(state, isShow?: boolean): void {
    state.isLoading = typeof isShow !== 'undefined' ? isShow : !state.isLoading
  },
}

const actions: ActionTree<ILayoutModuleState, IRootState> = {
  showPrimaryHeader({ commit }, { withBackButton, withNotificationMessage }) {
    commit('SHOW_PRIMARY_HEADER', { withBackButton, withNotificationMessage })
  },
  showSecondaryHeader({ commit }, { withBackButton, withNotificationMessage }) {
    commit('SHOW_SECONDARY_HEADER', { withBackButton, withNotificationMessage })
  },
  toggleLoading({ commit }, isShow?: boolean) {
    commit('TOGGLE_LOADING', isShow)
  },
}

const getters: GetterTree<ILayoutModuleState, IRootState> = {
  showBackButton: (state) => state.showBackButton,
  showNotificationMessage: (state) => state.showNotificationMessage,
  showPrimaryHeader: (state) => state.showPrimaryHeader,
  showSecondaryHeader: (state) => state.showSecondaryHeader,
  isLoading: (state) => state.isLoading,
}

const layoutModule: Module<ILayoutModuleState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default layoutModule
