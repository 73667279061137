import Vue from 'vue'
import Component from 'vue-class-component'
import { globalEventBus } from '@/main'
import { getContentByCurrentLanguage } from '@/utilities'

@Component
export class AppBase extends Vue {
  protected hasDeactivated: boolean = false
  protected navigatePreviousScreenHandler: Function = () => {}

  protected translate(textId: string, argument?: string[]) {
    try {
      return argument ? this.$t(textId, argument).toString() : this.$t(textId).toString()
    } catch (error) {
      return ''
    }
  }

  protected onNavigatePreviousScreen(): void {
    globalEventBus.$on('navigate-to-previous-screen', this.navigatePreviousScreenHandler)
  }

  protected clearNavigatePreviousScreen(): void {
    globalEventBus.$off('navigate-to-previous-screen', this.navigatePreviousScreenHandler)
  }

  protected getContentByCurrentLanguage(value) {
    return getContentByCurrentLanguage(value)
  }


}
