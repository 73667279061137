import {
  ICompanyMaster,
  IDocomoBikeShareInfoOrder,
  IDocomoBikeSharePortMaster,
  IOrderGateInfo,
  IOrderMaster,
  IOrderUsage,
  IPriceMaster,
  ITicketMaster,
} from '@/models'
import {
  CSA_TYPE,
  REVERSE_STATUS,
  TicketCategory,
  TicketMethod,
  TicketStatus,
  TicketType,
} from '@/utilities/constant'

function getCompanyIdsFor(ticketId: string, ticketMaster: any[]) {
  const ticketInfo = getTicketInfoFor(ticketId, ticketMaster)
  return ticketInfo ? Object.keys(ticketInfo.priceInfo.companys) : []
}

function getTicketInfoFor(ticketId: string, ticketMaster: ITicketMaster[]) {
  return ticketMaster.find((ticket) => ticket.id === ticketId)
}

function getCompanyInfoFor(companyId: string, companyMaster: ICompanyMaster[]) {
  return companyMaster.find((company) => company.id === companyId)
}

function getPriceInfoFor(priceId: string, priceMaster: IPriceMaster[]) {
  return priceMaster.find((price) => price.id === priceId)
}

function createTicketDetail(price: IPriceMaster, order: IOrderMaster) {
  const result: any = {}
  result.iconUrl = price.iconURL
  result.iconName = price.display
  result.amount = order.numbers[price.id]
  return result
}

function createTicketStatus(status: TicketStatus) {
  const result: any = {}
  result.value = status
  result.isUnused = status === TicketStatus.Unused
  result.isUsing = status === TicketStatus.Using
  result.isUsed = status === TicketStatus.Used
  result.isVoid = status === TicketStatus.Void
  return result
}

function createTicketMethod(method: TicketMethod) {
  const result: any = {}
  result.value = method
  result.isQrReader = method === TicketMethod.QrReader
  result.isSelfStart = method === TicketMethod.SelfStart
  result.isSelfQr = method === TicketMethod.SelfQr
  result.isOptionUse = method === TicketMethod.OptionUse
  result.isGateStart = method === TicketMethod.GateStart
  result.isDocomoBikeShareStart = method === TicketMethod.DocomoBikeShareStart
  return result
}

function createTicketAmount(totalAmount: number, usages: IOrderUsage[] | null) {
  const result: any = {}
  result.total = totalAmount
  result.available = usages ? totalAmount - usages.length : totalAmount
  result.isShow = totalAmount >= 2
  return result
}

function createTicketUsages(usage: IOrderUsage) {
  const result: any = {}
  result.date = usage.usageDateTime.toDate()
  result.method = createTicketMethod(usage.method)
  result.facilityID = usage.facilityID
  result.deviceID = usage.deviceID
  result.clerkID = usage.clerkID
  return result
}

function createTicketType(type: TicketType) {
  const result: any = {}
  result.value = type
  result.isTypeA = type === TicketType.TypeA
  result.isTypeB = type === TicketType.TypeB
  result.isTypeC = type === TicketType.TypeC
  return result
}

function createTickerGateInfo(gateInfo: IOrderGateInfo) {
  const result: IOrderGateInfo = {
    passDateTime: gateInfo.passDateTime,
    gateID: gateInfo.gateID,
  }
  return result
}

function createTicketCategory(category: TicketCategory) {
  const result: any = {}
  result.value = category
  result.isNormal = category === TicketCategory.Normal
  result.isDocomoBikeShare = category === TicketCategory.DocomoBikeShare
  result.isCodeTicket = category === TicketCategory.CodeTicket
  return result
}

function createDocomoBikeInfoOrder(bike: IDocomoBikeShareInfoOrder) {
  const result: any = {}
  result.csaType = createDocomoBikeCSAType(bike.csaType)
  result.portID = bike.portID
  result.portName = bike.portName
  result.passcode = bike.passcode
  result.supportID = bike.supportID
  result.cycleName = bike.cycleName
  result.phoneNumber = bike.phoneNumber
  result.identificationNumber = bike.cycleName
  result.reserveStatus = createDocomoBikeStatus(bike.reserveStatus)
  return result
}

function createDocomoBikeCSAType(type: CSA_TYPE) {
	const result: any = {}
	result.isPasscode = type === CSA_TYPE.passcode
	result.isQRcode = type === CSA_TYPE.qrcode
	return result
}

function createDocomoBikeStatus(reserveStatus: REVERSE_STATUS) {
  const result: any = {}
  result.isUnReserved = reserveStatus === REVERSE_STATUS.unreserved
  result.isReserved = reserveStatus === REVERSE_STATUS.reserved
  result.isUsing = reserveStatus === REVERSE_STATUS.using
  return result
}

export function createPurchasedTicketData(
  order: IOrderMaster,
  ticketMaster: ITicketMaster[],
  companyMaster: ICompanyMaster[],
  priceMaster: IPriceMaster[]
) {
  const ticketInfo: ITicketMaster | undefined = getTicketInfoFor(order.ticketID, ticketMaster)

  if (!ticketInfo) {
    return null
  }

  const companyIds: string[] = getCompanyIdsFor(order.ticketID, ticketMaster)
  const companyInfo: ICompanyMaster[] = companyIds
    .map((companyId) => getCompanyInfoFor(companyId, companyMaster))
    .filter((company): company is ICompanyMaster => !!company)
    .sort((a, b) => a.sortKey.localeCompare(b.sortKey))

  const priceInfo: IPriceMaster[] = Object.keys(order.numbers)
    .map((priceId) => getPriceInfoFor(priceId, priceMaster))
    .filter((price): price is IPriceMaster => !!price)

  return { order, ticketInfo, companyInfo, priceInfo }
}

export function createPurchasedTicket(purchasedTicketData: {
  order: IOrderMaster
  ticketInfo: ITicketMaster
  companyInfo: ICompanyMaster[]
  priceInfo: IPriceMaster[]
}) {
  const result: any = {}
  result.name = purchasedTicketData.ticketInfo.display.name
  result.company = purchasedTicketData.companyInfo
  result.purchasedDate = purchasedTicketData.order.purchaseDateTime.toDate()
  result.expireDate = purchasedTicketData.order.expireDateTime.toDate()
  result.id = purchasedTicketData.order.id
  result.ticketId = purchasedTicketData.ticketInfo.id
  result.orderId = purchasedTicketData.order.orderID
  result.canRefund = purchasedTicketData.order.canRefund
  result.canBuyOption = purchasedTicketData.order.canBuyOption
  result.canShowDetail = purchasedTicketData.order.canShowDetail
  result.canStartUsing = purchasedTicketData.order.canStartUsing
  result.canReadQR = purchasedTicketData.order.canReadQR
  result.isExpired = purchasedTicketData.order.isExpired
  result.isDisplayLimit = purchasedTicketData.order.isDisplayLimit
  result.howToUse = purchasedTicketData.ticketInfo.display.howToUse
  result.imageUrl = purchasedTicketData.ticketInfo.display.imageURL
  result.usageGuideHref = purchasedTicketData.ticketInfo.display.usageGuideHref
  result.usageAreaHref = purchasedTicketData.ticketInfo.display.usageAreaHref
  result.description = purchasedTicketData.ticketInfo.display.description
  result.status = createTicketStatus(purchasedTicketData.order.status)
  result.method = createTicketMethod(purchasedTicketData.ticketInfo.useStart.method)
  result.type = createTicketType(purchasedTicketData.order.type)
  result.category = createTicketCategory(purchasedTicketData.ticketInfo.category)
  result.amount = createTicketAmount(
    purchasedTicketData.order.counts,
    purchasedTicketData.order.usages
  )
  // @ts-ignore
  result.docomoBikeInfoOrder = purchasedTicketData.order.docomoBikeShareInfo
    // @ts-ignore
    ? createDocomoBikeInfoOrder(purchasedTicketData.order.docomoBikeShareInfo)
    : null
  result.usages = purchasedTicketData.order.usages
    ? purchasedTicketData.order.usages.map(createTicketUsages)
    : null
  result.gateInfo = purchasedTicketData.order.gateInfo
    ? purchasedTicketData.order.gateInfo.map(createTickerGateInfo)
    : null
  result.details = purchasedTicketData.priceInfo
    .map((price: any) => createTicketDetail(price, purchasedTicketData.order))
    .filter((detail: any) => detail.amount > 0)
  return result
}

export function getDocomoMasterByTicketId(
  ticketId: string,
  docomoBikeSharePortMaster: IDocomoBikeSharePortMaster[]
) {
  return docomoBikeSharePortMaster.filter((d) => {
    const port = d.id === ticketId
    const isPortInfoHaveItems = !!d.portInfoList.length

    return port && isPortInfoHaveItems
  })
}
