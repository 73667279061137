import { Component, Provide, Vue } from 'vue-property-decorator'
import { mapInit, reverseGeocoding } from '@/utilities'
import { defaultLocation, GeolocationService } from '@/services'
import { ILocation, IMapGeocoderReverse, IMapMarker, IMapPolyline, IPoint, } from './../custommodels/IMapCustom'

@Component
export class GMapMixin extends Vue {
  map: any = null // Map reference
  google: any = null // Google API
  markerSelected: IMapMarker | null = null // Marker selected from map
  currentLocation: ILocation = defaultLocation // Current user location

  constructor() {
    super()
  }

  protected async canBeUseGoogleMap(): Promise<boolean> {
    try {
      await this.getGoogleMapAPI()
      await this.getCurrentLocation()

      return Boolean(this.google).valueOf()
    } catch (error) {
      return false
    }
  }

  protected async canBeUseGoogleAPI(): Promise<boolean> {
    try {
      this.google = await this.getGoogleMapAPI()

      return Boolean(this.google).valueOf()
    } catch (error) {
      return false
    }
  }

  protected async reverseGeocoding(
    location: IPoint
  ): Promise<{
    isSuccess: boolean
    data: IMapGeocoderReverse[]
  }> {
    const { isSuccess, data } = await reverseGeocoding(this.google, { location })

    return { isSuccess, data }
  }

  @Provide('setGoogleAPI')
  protected setGoogleAPI(google: any) {
    this.google = google
  }

  @Provide('getCurrentLocation')
  protected async getCurrentLocation(): Promise<ILocation> {
    return (this.currentLocation = await GeolocationService.getCurrentLocation())
  }

  protected async getDetailCurrentLocation(
    currentLocation = this.currentLocation
  ): Promise<any> {
    if (!this.google) return

    const location: IPoint = {
      lat: currentLocation.lat,
      lng: currentLocation.lng,
    }

    const { isSuccess, data } = await this.reverseGeocoding(location)
    if (!isSuccess) {
      delete this.currentLocation.closet
      return { ...this.currentLocation }
    }

    const closet: IMapGeocoderReverse = data[0] || {}

    this.currentLocation.closet = {
      placeId: closet.placeId,
      address: closet.address,
      lat: closet.location.lat,
      lng: closet.location.lng,
    }

    return { ...this.currentLocation }
  }

  protected async getGoogleMapAPI(): Promise<null | object> {
    return new Promise((res) => {
      const timeout = setTimeout(() => {
        res((this.google = null))
      }, 5000)

      const promise = async () => {
        const google = await mapInit()
        clearTimeout(timeout)
        res((this.google = google as object | null))
      }

      promise()
    })
  }

  protected setMapZoom(zoom: number) {
    if (!this.$refs.mapRef) return

    //@ts-ignore
    this.$refs.mapRef.setZoom(zoom)
  }

  protected panMapTo(point: IPoint) {
    if (!this.$refs.mapRef) return

    //@ts-ignore
    this.$refs.mapRef.panTo(point)
  }

  protected setMapCenter(point: IPoint) {
    if (!this.$refs.mapRef) return

    //@ts-ignore
    this.$refs.mapRef.setCenter(point)
  }

  protected setMapMarkers(data: IMapMarker[] | IMapMarker, isReplace: boolean = true) {
    if (!this.$refs.mapRef) return

    //@ts-ignore
    this.$refs.mapRef.setMarkers(data, isReplace)
  }

  protected getMapMarkers(ids: string | string[]) {
    if (!this.$refs.mapRef) return

    //@ts-ignore
    return this.$refs.mapRef.getMarkers(ids)
  }

  protected fitMapBounds(data: IPoint[]) {
    if (!this.$refs.mapRef) return

    //@ts-ignore
    this.$refs.mapRef.fitBounds(data)
  }

  protected removeMapMarker(id: string | number) {
    if (!this.$refs.mapRef) return

    //@ts-ignore
    this.$refs.mapRef.removeMarker(id)
  }

  protected removeMapMarkerAll() {
    if (!this.$refs.mapRef) return

    //@ts-ignore
    this.$refs.mapRef.removeMarkerAll()
  }

  protected drawMapPolylines(data: IMapPolyline[] | IMapPolyline, isReplace: boolean = true) {
    if (!this.$refs.mapRef) return

    //@ts-ignore
    this.$refs.mapRef.drawPolylines(data, isReplace)
  }

  protected getMapPolylines(ids: string | string[]) {
    if (!this.$refs.mapRef) return

    //@ts-ignore
    return this.$refs.mapRef.getPolylines(ids)
  }

  protected removeMapPolyline(id: string | number) {
    if (!this.$refs.mapRef) return

    //@ts-ignore
    this.$refs.mapRef.removePolyline(id)
  }

  protected removeMapPolylineAll() {
    if (!this.$refs.mapRef) return

    //@ts-ignore
    this.$refs.mapRef.removePolylineAll()
  }
}
