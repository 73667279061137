<script lang="ts">
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { StoreMixin } from '@/mixins'

/**
 * 画面仕様書
 *
 * 機能名　  : ユーザ管理機能
 * 画面ＩＤ  : MTP-0400
 * 画面名　  : 登録情報削除完了画面
 * 概要　　  : 登録情報削除後の確認画面
 */
@Component
export default class MTP_0400 extends Mixins(StoreMixin) {
  constructor() {
    super()
  }

  created() {
    this.showPrimaryHeader({ withBackButton: false, withNotificationMessage: false })
  }
}
</script>

<template>
  <v-layout column fill-height justify-center id="mtp-0400">
    <v-spacer />
    <pre class="heading  mb-10 text-center">{{ $t('MTP-0400.001') }}</pre>
    <pre class="heading  mb-1">{{ $t('MTP-0400.002') }}</pre>
    <v-spacer />
  </v-layout>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/styles/_variables.scss';
.heading {
  font-size: 2rem;
  font-weight: 900;
}
</style>

