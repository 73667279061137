import Vue from 'vue'
import Vuetify, {
  VAlert,
  VApp,
  VAppBar,
  VBanner,
  VBottomNavigation,
  VBottomSheet,
  VBtn,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VCheckbox,
  VCol,
  VContainer,
  VContent,
  VDialog,
  VDivider,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VFadeTransition,
  VFooter,
  VForm,
  VIcon,
  VImg,
  VLayout,
  VList,
  VListItem,
  VListItemAvatar,
  VListItemContent,
  VListItemIcon,
  VListItemSubtitle,
  VListItemTitle,
  VMenu,
  VOverlay,
  VProgressCircular,
  VRadio,
  VRadioGroup,
  VRow,
  VSelect,
  VSheet,
  VSpacer,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
  VTabsSlider,
} from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VAppBar,
    VBanner,
    VBottomNavigation,
    VBottomSheet,
    VBtn,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VCheckbox,
    VCol,
    VContainer,
    VContent,
    VDialog,
    VDivider,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VExpansionPanels,
    VFadeTransition,
    VFooter,
    VForm,
    VIcon,
    VImg,
    VLayout,
    VList,
    VListItem,
    VListItemAvatar,
    VListItemContent,
    VListItemIcon,
    VListItemSubtitle,
    VListItemTitle,
    VMenu,
    VOverlay,
    VProgressCircular,
    VRadio,
    VRadioGroup,
    VRow,
    VSelect,
    VSheet,
    VSpacer,
    VTab,
    VTabItem,
    VTabs,
    VTabsItems,
    VTabsSlider
  },
  directives: {
    Ripple,
  },
})

export default new Vuetify({
  theme: {
    disable: false,
    dark: false,
    themes: {
      light: {
        anchor: '#80c0dd',
        error: '#ff0000',
        success: '#4c937b',
        accent: '#bdbdbd',
        grayDark: '#666',
        aqua: '#80c0dd',
        brownLight: '#e8e0ca',
      },
      dark: {
        anchor: '#80c0dd',
        error: '#ff0000',
        success: '#4c937b',
        accent: '#bdbdbd',
        grayDark: '#666',
        aqua: '#80c0dd',
        brownLight: '#e8e0ca',
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 375,
      md: 400,
      lg: 500,
    },
  },
  icons: {
    iconfont: 'mdiSvg'
  }
})
