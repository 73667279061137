import _Vue from 'vue'
import customizeStyle from '../../../customize.json'
import { base64ToUri } from '@/utilities/common'

function customTitle(): void {
  //Set title website by customize config
  document.title = customizeStyle.title

  //Set favicon website by customize config
  // const faviconEle = document.getElementById('favicon')
  // const faviconUri: string = base64ToUri(customizeStyle.favicon, 'x-icon')
  // if (!faviconEle) return
  // ;(faviconEle as HTMLLinkElement).href = faviconUri
}

function customStyle(): void {
  let header = document.head
  let style = document.createElement('style')
  let styleString = `
    .text-danger {
      color: ${customizeStyle.default.cautionText} !important;
    }
    #mtl-0000 .expiration-date-text {
      color: ${customizeStyle.MTL_0000.historyTab.expirationDate.textColor};
    }
    #mtl-0200 .border-line {
      border-style: dashed !important;
      border-color: ${customizeStyle.MTL_0200.borderLine.lineColor} !important;
      display: ${customizeStyle.MTL_0200.borderLine.visible === 'true' ? 'block' : 'none'} !important;
    }
    #mtl-0200 .border-line-left {
      border-left: 1px dashed ${customizeStyle.MTL_0200.borderLine.lineColor} !important;
      visibility: ${customizeStyle.MTL_0200.borderLine.visible === 'true' ? 'visible' : 'hidden'} !important;
    }
    #mtl-0200 .ticket-name {
      color: ${customizeStyle.MTL_0200.ticketName.textColor} !important;
      font-size: ${customizeStyle.MTL_0200.ticketName.textSize}px !important;
    }
    .v-content {
      background-color: ${customizeStyle.body.backgroundColor} !important;
    }
    .base-banner {
      background-color: ${customizeStyle.body.title.color} !important;
      color: ${customizeStyle.body.title.dark === 'true' ? '#fff' : '#666'} !important;
    }
    .v-banner.custom-banner {
      background-color: ${customizeStyle.body.backgroundColor} !important;
    }
    .alert-container {
      border-color: ${customizeStyle.header.frameColor} !important;
    }
    .color-frame-header {
      border-color: ${customizeStyle.header.frameColor} !important;
    }
    .background-color-header {
      background-color: ${customizeStyle.header.backgroundColor} !important;
    }
    .v-application .btn-outline {
      background-color: ${customizeStyle.default.buttonText} !important;
      border: 2px solid ${customizeStyle.default.primary} !important;
      color: ${customizeStyle.default.primary} !important;
      font-weight: bold !important;
    }
    .v-application .primary {
      background-color: ${customizeStyle.default.primary} !important;
      border-color: ${customizeStyle.default.primary} !important;
    }
    .v-application .primary--text {
      color: ${customizeStyle.default.primary} !important;
      caret-color: ${customizeStyle.default.primary} !important;
    }
    .v-application .v-btn.primary {
      background-color: ${customizeStyle.default.primary} !important;
      border-color: ${customizeStyle.default.primary} !important;
    }
    .v-application .v-btn:not(.v-btn--outlined) {
      color: ${customizeStyle.default.buttonText};
    }
    .v-application #qr-list-button .v-btn:not(.v-btn--outlined) .dash-left {
      border-left-color: ${customizeStyle.default.buttonText};
    }
    .v-application .v-btn.primary--text {
      color: ${customizeStyle.default.primary} !important;
      caret-color: ${customizeStyle.default.primary} !important;
    }
    .v-application .secondary {
      background-color: ${customizeStyle.default.secondary} !important;
      border-color: ${customizeStyle.default.secondary} !important;
    }
    .v-application .secondary--text {
      color: ${customizeStyle.default.secondary} !important;
      caret-color: ${customizeStyle.default.secondary} !important;
    }
    .v-application .v-btn.secondary {
      background-color: ${customizeStyle.default.secondary} !important;
      border-color: ${customizeStyle.default.secondary} !important;
    }
    .v-application .v-btn.secondary--text {
      color: ${customizeStyle.default.secondary} !important;
      caret-color: ${customizeStyle.default.secondary} !important;
    }
    .v-application .v-btn {
      color: ${customizeStyle.default.buttonText};
    }
    .theme--light.v-application,
    .theme--light.v-card,
    .theme--light.v-card .v-card__subtitle,
    .theme--light.v-card>.v-card__text,
    .theme--light.v-expansion-panels .v-expansion-panel
    {
      color: ${customizeStyle.default.normalText};
    }
    .expansion-panel {
      border: 1px solid ${customizeStyle.default.primary};
      background-color: ${customizeStyle.default.primary} !important;
    }
    .expansion-panel-secondary {
      border: 1px solid ${customizeStyle.default.secondary};
      background-color: ${customizeStyle.default.secondary} !important;
    }
    .background-primary {
      background-color: ${customizeStyle.default.primary};
    }
    .border-primary {
      border: 1px solid ${customizeStyle.default.primary} !important;
    }
    .border-color-primary {
      border-color: ${customizeStyle.default.primary} !important;
    }
    .border-dashed-green {
      border-style: dashed !important;
      border-color: ${customizeStyle.default.primary} !important;
    }
    .border-dashed-blue {
      border-style: dashed !important;
      border-color: ${customizeStyle.default.secondary} !important;
    }
    .tabs-container {
      border-bottom: 1px solid ${customizeStyle.default.primary} !important;
    }
    .dashed-border-left {
      border-left: 1px dashed ${customizeStyle.default.primary};
    }
    .border-primary-color {
      border-color: ${customizeStyle.default.primary} !important
    }
    .solid-top {
      border-top: 2px solid ${customizeStyle.default.primary} !important;
    }
    .dash-top {
      border-top: 1px dashed ${customizeStyle.default.primary};
    }
    .dash-right {
      border-right: 1px dashed ${customizeStyle.default.primary};
    }
    .input-container__input,
    .term-service-text,
    {
      color: ${customizeStyle.default.normalText} !important;
    }
    .input-container__input:disabled {
      color: ${customizeStyle.default.normalText} !important;
    }
    .number-circle {
      border: 1.5px solid ${customizeStyle.default.normalText};
    }
    .caution-text {
      color: ${customizeStyle.default.cautionText};
    }
    .precaution-text {
      color: ${customizeStyle.default.precautionText};
    }
    .normal-text {
      color: ${customizeStyle.default.normalText};
    }
    .footer-menu-bg-color {
      background-color: ${customizeStyle.footer.backgroundColor} !important;
    }
    footer {
      background-color: ${customizeStyle.footer.backgroundColor} !important;
      color: ${customizeStyle.footer.textColor} !important;
    }
      `
  style.textContent = styleString
  style.type = 'text/css'
  header.appendChild(style)
}

const customize = {
  // every plugin for Vue.js needs install method
  // this method will run after Vue.use(<your-plugin-here>) is executed
  install: (Vue: typeof _Vue, options?: any) => {
    Vue.prototype.$customApp = {
      customApp: () => {
        customTitle()
        customStyle()
      },
    }
  },
}

interface CustomApp {
  customApp: Function
}

declare module 'vue/types/vue' {
  interface Vue {
    $customApp: CustomApp
  }
}

export default customize
