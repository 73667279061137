import Vue from 'vue'
import Vuex, { ModuleTree } from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import { IRootState } from './utilities'

import authModule from './modules/auth'
import footerMenuModule from './modules/footer-menu'
import ticketModule from './modules/ticket'
import layoutModule from './modules/layout'
import systemModule from './modules/system'
import dbModule from './modules/database'
import ticketPurchasedModule from './modules/ticket-purchased'
import gmapLocaleModule from '@/store/modules/gmap-locale'

Vue.use(Vuex)

const modules: ModuleTree<IRootState> = {
  auth: authModule,
  footerMenu: footerMenuModule,
  ticket: ticketModule,
  layout: layoutModule,
  system: systemModule,
  database: dbModule,
  ticketPurchased: ticketPurchasedModule,
  gmapLocale: gmapLocaleModule
}

export default new Vuex.Store({
  modules,
  strict: false,
  mutations: {
    ...vuexfireMutations,
  },
})
