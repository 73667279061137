import Vue from 'vue'
import VueI18n, { Locale, Path } from 'vue-i18n'

import en from './en.json'
import ja from './ja.json'
import zhCN from './zhCN.json'
import zhTW from './zhTW.json'
import ko from './ko.json'
import { StorageService } from '@/services/storage'

Vue.use(VueI18n)

export enum LanguageCode {
  En = 'en',
  Ja = 'ja',
  ZhCN = 'zhCN',
  ZhTW = 'zhTW',
  Ko = 'ko',
}

function getLanguage() {
  return StorageService.getCurrentLanguage() || LanguageCode.Ja
}

export default new VueI18n({
  locale: getLanguage(),
  messages: { ja, en, zhCN, zhTW, ko },
  fallbackLocale: LanguageCode.Ja,
  missing(locale: Locale, key: Path, vm: Vue | undefined): string | void {
    if (key.includes('FIREBASE')) {
      return key.includes('message1')
        ? vm!.$t('FIREBASE.ERROR.message1').toString()
        : vm!.$t('FIREBASE.ERROR.message2').toString()
    }

    if (key.includes('D10')) {
      return key.includes('message1')
        ? vm!.$t('D10.d10/other-error.message1').toString()
        : vm!.$t('D10.d10/other-error.message2').toString()
    }

    if (key.includes('D11')) {
      return key.includes('message1')
        ? vm!.$t('D11.ERROR.message1').toString()
        : vm!.$t('D11.ERROR.message2').toString()
    }
  },
})
