import { CreditCardValidateRule } from '@/utilities/constant'

export class Validate {
  public static isEmailEntered(email: string) {
    return email.length > 0
  }

  public static isEmail(email: string) {
    // currently use RFC 5322 regex
    // eslint-disable-next-line no-empty-character-class
    const reg = /([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])/
    return reg.test(email)
  }

  public static isUsernameEntered(username: string) {
    return username.length > 0
  }

  public static isPasswordEntered(password: string) {
    return password.length > 0
  }

  public static isPasswordMatched(password: string, passwordConfirm: string) {
    return password === passwordConfirm
  }

  public static isPassword(password: string) {
    const MINIMUM_PASSWORD_LENGTH = 6
    return password.length >= MINIMUM_PASSWORD_LENGTH
  }

  public static isCardNoEntered(cardno: string) {
    return cardno.length > 0
  }

  public static isSecurityCodeEntered(securitycode: string) {
    return securitycode.length > 0
  }

  public static isEmpty(value: string) {
    return !value || value.length === 0
  }

  public static checkCreditCardNumberHasReachedMaxLength(value: string) {
    return value.length > CreditCardValidateRule.CardNumberMaxLength
  }

  public static checkCreditCardSecurityCodeHasReachedMaxLength(value: string) {
    return value.length > CreditCardValidateRule.SecurityCodeMaxLength
  }

  public static isDoubleByteCharacters(value: string) {
    // Regex for matching full-width Letters (zenkaku 全角)
    const letters = /[Ａ-ｚ]/g

    // Regex for matching full-width Numbers (zenkaku 全角)
    const numbers = /[０-９]/g

    // Regex for matching ALL Japanese common & uncommon Kanji
    const kanji = /[一-龯]/g

    // Regex for matching Hirgana or Katakana
    const characters = /[ぁ-んァ-ン]/g

    // Regex for matching basic punctuation or random other characters
    // eslint-disable-next-line no-irregular-whitespace
    const others = /[　、。’＿－＋＝｛｝［］／＼｜＜＞，．：；＂＇！＠＃＄％＾＆＊（）｀～]/g

    return this.composeRegex(letters, numbers, kanji, characters, others).test(value)
  }

  private static composeRegex(...regexes: RegExp[]) {
    return new RegExp(regexes.map((regex: RegExp) => regex.source).join('|'))
  }
}
