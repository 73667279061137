import firebase from 'firebase/app'
import 'firebase/firestore'
import { IGateMaster } from '@/models'

export class FirestoreService {
  private static db = firebase.firestore()

  public static getUserPermission(email: string) {
    return this.db
      .collection('administrators')
      .doc(email)
      .get()
  }

  public static getAdministratorMaster() {
    return this.db.collection('administrators').orderBy('email')
  }

  public static getCategoryMaster() {
    return this.db
      .collection('categoryMaster')
      .where('isDelete', '==', false)
      .orderBy('sortKey')
  }

  public static getCompanyMaster() {
    return this.db
      .collection('companyMaster')
      .where('isDelete', '==', false)
      .orderBy('sortKey')
  }

  public static getFacilityMaster() {
    return this.db
      .collection('facilityMaster')
      .where('isDelete', '==', false)
      .orderBy('sortKey')
  }

  public static getGroupMaster() {
    return this.db
      .collection('groupMaster')
      .where('isDelete', '==', false)
      .orderBy('sortKey')
  }

  public static getOptionMaster() {
    return this.db
      .collection('optionMaster')
      .where('isDelete', '==', false)
      .orderBy('sortKey')
  }

  public static getPriceMaster() {
    return this.db.collection('priceMaster').orderBy('sortKey')
  }

  public static getTicketMaster() {
    return this.db
      .collection('ticketMaster')
      .where('isDelete', '==', false)
      .orderBy('sortKey')
  }

  public static getSystemProperties() {
    return this.getSystemMaster().doc('properties')
  }

  /**
   * @description get api endpoint configuration for backend
   */
  public static getSystemEnvironment() {
    return this.getSystemMaster().doc('environment')
  }

  public static getSystemRouteSearch() {
    return this.getSystemMaster().doc('routeSearch')
  }

  public static getUserTransactions(saleID: string) {
    return this.db.collection('sales').doc(saleID)
  }

  public static getTicketsUnuse(userID: string) {
    return this.db
      .collectionGroup('tickets')
      .where('userID', '==', userID)
      .where('status', '==', 'UNUSE')
      .where('isHide', '==', false)
      .orderBy('purchaseDateTime', 'desc')
  }

  public static getTicketsUsing(userID: string) {
    return this.db
      .collectionGroup('tickets')
      .where('userID', '==', userID)
      .where('status', '==', 'USING')
      .where('isHide', '==', false)
      .orderBy('purchaseDateTime', 'desc')
  }

  public static getTicketsUsed(userID: string, displayHistoryLimit: number) {
    return this.db
      .collectionGroup('tickets')
      .where('userID', '==', userID)
      .where('status', '==', 'USED')
      .where('isHide', '==', false)
      .orderBy('purchaseDateTime', 'desc')
      .limit(displayHistoryLimit)
  }

  public static getTicketFor(userID: string, orderID: string, purchasedTicketID: string) {
    return this.db
      .collection('users/' + userID + '/orders/' + orderID + '/tickets')
      .doc(purchasedTicketID)
  }

  public static getPackOrdersUnuse(userID: string) {
    return this.db
      .collectionGroup('tickets')
      .where('userID', '==', userID)
      .where('status', '==', 'UNUSE')
      .where('isHide', '==', true)
      .orderBy('purchaseDateTime', 'desc')
  }

  public static getPackOrdersUsing(userID: string) {
    return this.db
      .collectionGroup('tickets')
      .where('userID', '==', userID)
      .where('status', '==', 'USING')
      .where('isHide', '==', true)
      .orderBy('purchaseDateTime', 'desc')
  }

  public static getPackOrdersUsed(userID: string) {
    return this.db
      .collectionGroup('tickets')
      .where('userID', '==', userID)
      .where('status', '==', 'USED')
      .where('isHide', '==', true)
      .orderBy('purchaseDateTime', 'desc')
  }

  public static getOrders(userID: string) {
    return this.db.collectionGroup('orders').where('userID', '==', userID)
  }

  private static getSystemMaster() {
    return this.db.collection('system')
  }

  public static getUserData(userID: string) {
    return this.db.collection('users').doc(userID)
  }

  public static getExternalLinkMaster() {
    return this.db
      .collection('externalLinkMaster')
      .where('isDelete', '==', false)
      .orderBy('sortKey')
  }

  public static getExternalLowerLinkMaster() {
    return this.db
      .collection("externalLowerLinkMaster")
      .where("isDelete", "==", false)
      .orderBy("sortKey")
  }

  public static getTicketRegular() {
    return this.db
      .collection('ticketMaster')
      .where('isDelete', '==', 'false')
      .where('category', '==', 'NORMAL')
      .orderBy('sortKey')
  }

  public static getTicketCode() {
    return this.db
      .collection('ticketMaster')
      .where('isDelete', '==', 'false')
      .where('category', '==', 'CODE_TICKET')
      .orderBy('sortKey')
  }

  public static getTicketDocomo() {
    return this.db
      .collection('ticketMaster')
      .where('isDelete', '==', 'false')
      .where('category', '==', 'DOCOMO_BIKESHARE')
      .orderBy('sortKey')
  }

  public static getDocomoBikeSharePortMaster() {
    return this.db.collection('docomoBikeSharePortMaster')
  }


  public static async getGateInfo(gateID): Promise<IGateMaster | null> {
    try {
      const doc = await firebase.firestore().collection('gateMaster').doc(gateID).get()
      if (doc.exists) {
        return doc.data() as IGateMaster
      }
      return null
    } catch (e) {
      return null
    }
  }
}
