import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { IOrderMaster } from '@/models'
import { flatten } from 'lodash'

@Component
export class FetchDataMixin extends Vue {
  @Action('database/getGroupMaster') private fetchGroupMaster!: () => Promise<any>
  @Action('database/getCategoryMaster') private fetchCategoryMaster!: () => Promise<any>
  @Action('database/getTicketMaster') private fetchTicketMaster!: () => Promise<any>
  @Action('database/getPriceMaster') private fetchPriceMaster!: () => Promise<any>
  @Action('database/getOptionMaster') private fetchOptionMaster!: () => Promise<any>
  @Action('database/getCompanyMaster') private fetchCompanyMaster!: () => Promise<any>
  @Action('database/getExternalLinkMaster') private fetchExternalLinkMaster!: () => Promise<any>
  @Action('database/getExternalLowerLinkMaster') private fetchExternalLowerLinkMaster!: () => Promise<any>
  @Action('database/getOrdersUnuse') private fetchOrdersUnused!: () => Promise<any>
  @Action('database/getOrdersUsing') private fetchOrdersUsing!: () => Promise<any>
  @Action('database/getOrdersUsed') private fetchOrdersUsed!: () => Promise<any>
  @Action('database/getSaleMaster') protected fetchSaleMaster!: () => Promise<any>
  @Getter('ticketPurchased/saleIdResponse') protected isRedirectFromPaymented!: boolean
  protected async fetchAppMainData(): Promise<any> {
    await Promise.all([
      this.fetchTicketMaster(),
      this.fetchPriceMaster(),
      this.fetchCompanyMaster(),
      this.fetchOrdersUnused(),
      this.fetchOrdersUsing(),
      this.fetchOrdersUsed(),
      this.getSaleAfterPaymented(),
      this.fetchExternalLinkMaster(),
      this.fetchExternalLowerLinkMaster(),
    ])

    await this.getGateInfoForPurchasedTicket()
  }

  protected async getSaleAfterPaymented() {
    if (this.isRedirectFromPaymented) {
      await this.fetchSaleMaster()
    }
  }

  private async getGateInfoForPurchasedTicket() {
    const gateIDs = this.getGateIDFromPurchasedOrders()
    const promises = gateIDs.map(id => this.$store.dispatch('database/getGateInfo', id))
    await Promise.all(promises)
  }

  private getGateIDFromPurchasedOrders() {
    const ordersUnusedGateID: string[] = this.getGateIDFrom(this.$store.state.database.ordersUnuse)
    const ordersUsingGateID: string[] = this.getGateIDFrom(this.$store.state.database.ordersUsing)
    const ordersUsedGateID: string[] = this.getGateIDFrom(this.$store.state.database.ordersUsed)
    const gateIDs = [...ordersUnusedGateID, ...ordersUsingGateID, ...ordersUsedGateID]
    return this.extractDistinctValue(gateIDs)
  }

  private getGateIDFrom(orders: IOrderMaster[]): string[] {
    const checkGateInfoExist = (order: IOrderMaster) => order.gateInfo
    const ordersHasGateID = orders.filter(checkGateInfoExist)
    const gateIDs: string[][] = ordersHasGateID.map(order => order.gateInfo!.map(g => g.gateID))
    return flatten(gateIDs)
  }

  private extractDistinctValue(gateIDs: string[]) {
    return [...new Set(gateIDs)]
  }
}
