import { ILocation } from './../custommodels'

const defaultOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 15000,
}

export const defaultLocation: ILocation = {
  lat: 0,
  lng: 0,
  hasPermission: false,
}

export class GeolocationService {
  public static grantPermission(): Geolocation | null {
    try {
      return window.navigator.geolocation
    } catch (error) {
      return null
    }
  }

  public static getCurrentLocation(): Promise<ILocation | any> {
    const geolocation = this.grantPermission()
    const isHttps = document.location.protocol === 'https'

    if (!geolocation) return Promise.resolve(defaultLocation)

    return new Promise<ILocation>((res) => {
      geolocation.getCurrentPosition(
        this.handleGetPosSuccess(res),
        this.handleGetPosFailed(res),
        isHttps ? defaultOptions : { timeout: defaultOptions.timeout }
      )
    })
  }

  private static handleGetPosSuccess = (res: any) => (data: any) => {
    const { coords = {} } = data
    const { latitude: lat = 0, longitude: lng = 0 } = coords
    const hasPermission = !!lat && !!lng

    res({
      lat,
      lng,
      hasPermission,
    })
  }

  private static handleGetPosFailed = (res: any) => (error: any) => {
    res(defaultLocation)
  }
}
