<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class AppLoader extends Vue {
  constructor() {
    super()
  }
}
</script>

<template>
  <v-overlay opacity="0.75">
    <v-progress-circular color="primary" indeterminate />
  </v-overlay>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
