import { format } from 'date-fns'
import { enUS, ja, ko, zhCN, zhTW } from 'date-fns/locale'
import i18n, { LanguageCode } from '@/i18n'

export const MOMENT_FORMAT = {
  YYYY_MM_DD_HH_MM_SS: 'yyyy-MM-dd HH:mm:ss',
  DD_MM_YYYY: 'dd-MM-yyyy',
  HH_MM_SS: 'HH:mm:ss',
  HH_MM: 'HH:mm',
  YYYY: 'yyyy',
  MM: 'MM',
  ddd: '(E)',
  MM_DD_ddd: 'MM/dd (E)',
  YYYYMMDDHHmm: 'yyyyMMddHHmm',
  YYYYMMDD: 'yyyyMMdd',
  MM_DD_ddd_HH_mm: 'MM/dd(E) HH:mm',
  MM_DD_HH_mm: 'MM/dd HH:mm',
  MM_DD: 'MM/dd',
}

const LOCALE_RECORD: Record<LanguageCode, Locale> = {
  [LanguageCode.Ja]: ja,
  [LanguageCode.En]: enUS,
  [LanguageCode.ZhCN]: zhCN,
  [LanguageCode.ZhTW]: zhTW,
  [LanguageCode.Ko]: ko,
}

export function formatDateTime(value: Date | number): string {
  return format(value, 'yyyy/MM/dd HH:mm')
}

export function formatDateTimeWithDayOfWeek(value: Date | number): string {
  return format(value, 'yyyy/MM/dd (E) HH:mm', { locale: LOCALE_RECORD[i18n.locale] })
}

export function formatDateTimeWithDayOfWeekWithSecond(value: Date | number): string {
  return format(value, 'yyyy/MM/dd (E) HH:mm:ss', { locale: LOCALE_RECORD[i18n.locale] })
}

export function formatDateTimeWithFormat(value: Date | number, formatPattern: string): string {
  return format(value, formatPattern, { locale: LOCALE_RECORD[i18n.locale] })
}

export function formatDateTimePicker(value: Date | number, formatPattern: string, dateOfWeek: string): string {
  try {
    return `${format(value, formatPattern)} (${dateOfWeek})`
  } catch {
    return ''
  }
}

/**
 *
 * @param {*} value Date | number | string
 * @param {*} formatPattern string
 */
export function parseDate2String(
  value: Date | number | string,
  formatPattern: string = MOMENT_FORMAT.YYYY_MM_DD_HH_MM_SS
) {
  if (typeof value === 'string') {
    value = new Date(value)
  }
  return format(value, formatPattern)
}

/**
 *
 * @param {*} value Date | number | string
 * @param {*} formatPattern string
 */
export function parseJaDate2String(
  value: Date | number | string,
  formatPattern: string = MOMENT_FORMAT.YYYY_MM_DD_HH_MM_SS
) {
  value = new Date(value).toLocaleString('en-US', { timeZone: 'Asia/Tokyo' })

  return format(new Date(value), formatPattern)
}
