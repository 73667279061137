import { Component, Prop, Vue } from 'vue-property-decorator'
import { ScreenId } from '../utilities/constant'

@Component
export class ProcessMixin extends Vue {
  protected currentScreen: string = ''

  protected changeScreen(screenId: string) {
    this.currentScreen = screenId
    window.scroll(0, 0)
  }
}
