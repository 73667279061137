import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import Dialog from '@/plugins/dialog'
import Icon from '@/plugins/icon'
import '@/plugins/pipe'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'

import 'pickerjs/dist/picker.min.css'
import '@/styles/index.scss'
import customApp from '@/plugins/customApp'
import { ScreenId } from '@/utilities'
import { ApiService } from '@/services'
import { firestorePlugin } from 'vuefire'

Vue.use(firestorePlugin)
Vue.use(customApp)
Vue.use(Dialog)
Vue.use(Icon)

// If error wasn't handled in local component
// Or application fall into bad state, and unable to continue processing
// Notify user that it detected an error
// And attempt to recover by reload application
Vue.config.errorHandler = (err, vm, info) => {
  try {
    ApiService.writeLog(err.message + ":" + err.stack)
  } catch {
    // nothing
  }
  vm.$dialog.showError(ScreenId.MLC_0100, 'D11.ERROR', function reloadApp() {
    window.location.reload()
  })
}

Vue.config.productionTip = false

export const globalEventBus = new Vue()
export const footerMenuEventBus = new Vue()
export const languageEventBus = new Vue()

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#qrApp')
