export const filePath = {
  iconOk: require('@/assets/icons/train-cont-27.svg'),
  iconAvatar: require('@/assets/icons/train-cont-28.svg'),
  iconTicket: require('@/assets/icons/train-cont-29.svg'),
  iconQrCode: require('@/assets/icons/train-cont-30.svg'),
  iconPencil: require('@/assets/icons/train-cont-31.svg'),
  iconCreditCard: require('@/assets/icons/train-cont-32.svg'),
  iconHome: require('@/assets/icons/train-cont-33.svg'),
  iconLogout: require('@/assets/icons/train-cont-34.svg'),
  iconTicketOption: require('@/assets/icons/icon-ticket-option.svg'),
  iconMaintenance: require('@/assets/icons/train-dec_setting.svg'),
  iconGoogle: require('@/assets/icons/train_google.svg'),
  iconFacebook: require('@/assets/icons/train_fb.svg'),
  iconEmail: require('@/assets/icons/train_mail.svg'),
  iconPassword: require('@/assets/icons/train_lock.svg'),
  iconCheckBox: require('@/assets/icons/checkbox.png'),
  iconCheckBoxNone: require('@/assets/icons/checkbox-none.png'),
  iconCameraFocus: require('@/assets/icons/frame.svg'),
  iconMapMarkerDefault: require('@/assets/icons/marker_default.png'),
  iconMapMarkerSeleted: require('@/assets/icons/marker_selected.png'),
  iconMapMarkerCustom: require('@/assets/icons/marker-custom.svg'),
  iconVisa: require('@/assets/icons/visa.jpg'),
  iconMastercard: require('@/assets/icons/mc.png'),
  iconJCB: require('@/assets/icons/JCB.gif'),
  iconAmericanExpress: require('@/assets/icons/amex.jpg'),
  iconDinersClub: require('@/assets/icons/diners-club.jpg'),
  iconDiscover: require('@/assets/icons/discover.jpg'),
  howToUseDocomo: require('@/assets/how_to_use_docomo.png'),
  howToUseDocomoQrcode: require('@/assets/how_to_use_qr_docomo.png'),
}
