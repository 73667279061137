var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.$store.getters['layout/showPrimaryHeader'])?_c('v-app-bar',{staticClass:"pa-0",attrs:{"height":"60","elevate-on-scroll":"","app":""}},[_c('v-container',{staticClass:"background-color-header",staticStyle:{"padding-bottom":"8px"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"position":"relative"}},[_c('div',{style:({ width: _vm.$store.getters['layout/showBackButton'] ? 'max-content' : '41px' })},[_c('p',{directives:[{name:"ripple",rawName:"v-ripple"},{name:"show",rawName:"v-show",value:(_vm.$store.getters['layout/showBackButton']),expression:"$store.getters['layout/showBackButton']"}],staticClass:"back-btn",staticStyle:{"width":"max-content"},on:{"click":_vm.handleBackButtonClick}},[_vm._v(_vm._s(_vm.$t('Header.002')))])]),_c('v-spacer'),_c('v-spacer'),_c('img',{staticClass:"d-block logo",style:({
            width: _vm.secondary.width,
            height: _vm.secondary.height,
          }),attrs:{"src":_vm.secondary.icon,"alt":""},on:{"click":_vm.handleAppNameClick}}),_c('v-spacer'),_c('v-spacer'),_c('select-language')],1)])],1):_vm._e(),(_vm.$store.getters['layout/showSecondaryHeader'])?[_c('v-container',{staticClass:"background-color-header"},[_c('select-language',{staticStyle:{"margin-top":"5px"}}),_c('div',{staticClass:"avatar"},[_c('img',{staticClass:"avatar__icon",style:({
            width: _vm.primary.width,
            height: _vm.primary.height,
          }),attrs:{"src":_vm.primary.icon,"alt":""}})])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }