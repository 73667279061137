export const Regex = {
  CURRENCY_FORMAT: /(\d)(?=(\d{3})+(?!\d))/g,
}

export enum ProviderId {
  Password = 'password',
  Google = 'google.com',
}

export enum TicketStatus {
  Unused = 'UNUSE',
  Using = 'USING',
  Used = 'USED',
  Void = 'VOID',
}

export enum TicketMethod {
  QrReader = 'QR_READER',
  SelfStart = 'SELF_START',
  SelfQr = 'SELF_QR',
  OptionUse = 'OPTION_USE',
  GateStart = 'GATE_START',
  DocomoBikeShareStart = 'DOCOMO_BIKESHARE_START',
}

export enum TicketType {
  TypeA = 'typeA',
  TypeB = 'typeB',
  TypeC = 'typeC',
}

export enum TicketCategory {
  Normal = 'NORMAL',
  CodeTicket = 'CODE_TICKET',
  DocomoBikeShare = 'DOCOMO_BIKESHARE',
}

export enum Mtp0110ScreenType {
  Username,
  Email,
  Password,
}

export enum ScreenId {
  MLO_0000 = 'MLO-0000',
  MLO_0100 = 'MLO-0100',
  MLO_0110 = 'MLO-0110',
  MLO_0200 = 'MLO-0200',
  MLO_0210 = 'MLO-0210',
  MLO_0900 = 'MLO-0900',
  MLC_0100 = 'MLC-0100',
  MLC_0110 = 'MLC-0110',
  MLC_0120 = 'MLC-0120',
  MLC_0210 = 'MLC_0210',
  MLC_0211 = 'MLC_0211',
  MLC_0212 = 'MLC_0212',
  MLC_0230 = 'MLC_0230',
  MLC_0240 = 'MLC_0240',
  MLC_0310 = 'MLC-0310',
  MLC_0320 = 'MLC-0320',
  MLC_0330 = 'MLC-0330',
  MLC_0900 = 'MLC-0900',
  MTB_0000 = 'MTB-0000',
  MTB_0100 = 'MTB-0100',
  MTB_0101 = 'MTB-0101',
  MTB_0110 = 'MTB-0110',
  MTB_0120 = 'MTB-0120',
  MTB_0200 = 'MTB-0200',
  MTB_0300 = 'MTB-0300',
  MTB_0310 = 'MTB-0310',
  MTB_0320 = 'MTB-0320',
  MTB_0330 = 'MTB-0330',
  MTB_0400 = 'MTB-0400',
  MTB_0401 = 'MTB-0401',
  MTL_0000 = 'MTL-0000',
  MTL_0100 = 'MTL-0100',
  MTL_0101 = 'MTL-0101',
  MTL_0102 = 'MTL-0102',
  MTL_0200 = 'MTL-0200',
  MTL_0300 = 'MTL-0300',
  MTL_0310 = 'MTL-0310',
  MTL_0400 = 'MTL-0400',
  MTL_0410 = 'MTL-0410',
  MTL_0420 = 'MTL-0420',
  MTL_0500 = 'MTL-0500',
  MTL_0501 = 'MTL-0501',
  MTP_0000 = 'MTP-0000',
  MTP_0100 = 'MTP-0100',
  MTP_0110 = 'MTP-0110',
  MTP_0200 = 'MTP-0200',
  MTP_0300 = 'MTP-0300',
  MTP_0400 = 'MTP-0400',
  MGT_0000 = 'MGT-0000',
  MGT_0100 = 'MGT-0100',
  MRO_0000 = 'MRO-0000',
  MRO_0010 = 'MRO-0010',
  MRO_0011 = 'MRO-0011',
  MRO_0020 = 'MRO-0020',
  MRO_0030 = 'MRO-0030',
  MRO_0031 = 'MRO-0031',
  MRO_0100 = 'MRO-0100',
  MRO_0200 = 'MRO-0200',
  MRO_0210 = 'MRO-0210',
  MRO_0300 = 'MRO-0300',
  MBI_0000 = 'MBI-0000',
  MBI_0100 = 'MBI-0100',
  MBI_0110 = 'MBI-0110',
  MBI_0120 = 'MBI-0120',
  MBI_0200 = 'MBI-0200',
  MBI_0300 = 'MBI-0300',
  MBI_0400 = 'MBI-0400',
}

export enum FooterMenuTab {
  Top = 'TopTab',
  RouteSearch = 'RouteSearchTab',
  MyTicket = 'MyTicketTab',
}

export enum ScreenProcess {
  // Top
  CancelPayment = 'cancel-payment',
  FinalPayment = 'final-payment',
  CancelPaymentForRepurchase = 'cancel-payment-for-repurchase',
  FinalPaymentForRepurchase = 'final-payment-for-repurchase',
  EditProfile = 'editting-profile',
  CheckProfile = 'looking-profile',
  PurchaseOptionTicket = 'purchase-option-process',
  PurchaseTicket = 'purchase-ticket-process',
  CreditCardManagement = 'MTB-0310',
  PurchaseRouteTicket = 'route-ticket-purchase-process',
  PurchaseOptionTicketFromTicketManagement = 'ticket-management-purchase-option-process',

  ExternalLowerLinks = 'external-lower-links',

  // Route Search
  RouteSearch = 'route-search-process',

  // My Ticket
  TicketManagementProcess = 'ticket-management-process',
  QrTicketSelectionProcess = 'qr-ticket-selection-process',
  GateQrTicketSelectionProcess = 'gate-qr-ticket-selection-process',
}

export enum FirebaseApiName {
  getRedirectResult = 'getRedirectResult',
  signInWithEmailAndPassword = 'signInWithEmailAndPassword',
  signInWithRedirect = 'signInWithRedirect',
  createUserWithEmailAndPassword = 'createUserWithEmailAndPassword',
  sendPasswordResetEmail = 'sendPasswordResetEmail',
  updateEmail = 'updateEmail',
  updatePassword = 'updatePassword',
  sendEmailVerification = 'sendEmailVerification',
}

export enum MAP_COLOR {
  WHITE = '#FFFFFF',
  RED = '#FF0000',
}

export const MAP_OPTIONS = {
  ZOOM: 17,
  CENTER: { lat: 35.652832, lng: 139.839478 },
  DEFAULT_UI: false,
  FULL_CONTROL: false,
  ZOOM_CONTROL: false,
  TYPE_CONTROL: false,
  VIEW_CONTROL: false,
  SCALE_CONTROL: false,
  ROTATE_CONTROL: false,
  CLICKABLE_ICON: false,
}

export enum MAP_MARKER_TYPE {
  SYMBOL = 'symbol',
  IMAGE = 'image',
}

export enum MAP_GEOCODER_LOCATION_TYPE {
  APPROXIMATE = 'APPROXIMATE',
  GEOMETRIC_CENTER = 'GEOMETRIC_CENTER',
  RANGE_INTERPOLATED = 'RANGE_INTERPOLATED',
  ROOFTOP = 'ROOFTOP',
}

export enum MAP_GEOCODER_STATUS {
  OK = 'OK',
  ERROR = 'ERROR',
  ZERO_RESULTS = 'ZERO_RESULTS',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  REQUEST_DENIED = 'REQUEST_DENIED',
  INVALID_REQUEST = 'INVALID_REQUEST',
  OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
}

export const ROUTE_SELECT_FIELD = {
  DEPARTURE: 0,
  ARRIVAL: 1,
  WAYPOINT_ONE: 2,
  WAYPOINT_TWO: 3,
}

export enum CandidateSiteTypeIcon {
  marker = 'mdiMapMarker',
}

export const CURRENT_LOCATION = '現在地'

export const TARGET_POINT_TYPE = {
  stationCode: 'stationCode',
  placeID: 'placeID',
  geoPoint: 'geoPoint',
}

export enum BATTERY_LEVEL {
  batterylow = 0,
  battery30 = 1,
  battery80 = 2,
  batteryfull = 3,
}

export enum REVERSE_STATUS {
  unreserved = 'UNRESERVED',
  reserved = 'RESERVED',
  using = 'USING',
}

export enum CSA_TYPE {
  passcode = 'PASSCODE',
  qrcode = 'QRCODE',
}

export enum TransportInfoKey {
  WALK = 'walk',
  PLANE = 'plane',
  BULLETTRAIN = 'bullettrain',
  LIMITEDEXPRESS = 'limitedexpress',
  CAR = 'car',
  BUS = 'bus',
  SHIP = 'ship',
  CYCLE = 'cycle',
}

export enum TransportType {
  walk = 'walk',
  plane = 'plane',
  bullettrain = 'bullettrain',
  limitedexpress = 'limitedexpress',
  car = 'car',
  bus = 'bus',
  ship = 'ship',
  cycle = 'cycle',
}
export enum MovingMethod {
  walk = 'walk',
  plane = 'plane',
  train = 'train',
  car = 'car',
  bus = 'bus',
  ship = 'ship',
  cycle = 'cycle',
  strange = 'strange',
}

export enum MovementIcon {
  walk = 'mdiWalk',
  plane = 'mdiAirplane',
  train = 'mdiTrain',
  car = 'mdiTaxi',
  bus = 'mdiBusStop',
  ship = 'mdiFerry',
  cycle = 'mdiBicycle',
  strange = 'mdiCar',
}

export enum DatetimePickerSearchType {
  departure = '出発',
  arrival = '到着',
  firstTrain = '始発',
  lastTrain = '終電',
}

export enum RouteResultSortType {
  time = '到着が早い',
  price = '料金が安い',
  transfer = '乗換が少ない',
}

export const DATE_TIME_PICKER_SEARCH_TYPE = {
  departure: 'departure',
  arrival: 'arrival',
  firstTrain: 'firstTrain',
  lastTrain: 'lastTrain',
}

export const ROUTE_RESULT_SORT_TYPE = {
  time: 'time',
  price: 'price',
  transfer: 'transfer',
}
export enum CreditCardValidateRule {
  SecurityCodeMaxLength = 4,
  CardNumberMaxLength = 16,
  CardHolderNameMaxLength = 50,
}

export const PAY_FREQUENCY_OPTIONS = [2, 3, 5, 6, 10, 12, 15, 18, 20, 24]

export const DATE_PICKER_DRUM_ROLL_MULTIPLE = 8
export const DATE_PICKER_DRUM_ROLL_PREV_DATE = 7

export enum FooterMenuEvent {
  ChangeTab = '[Footer Menu] change activated tab',
}

export enum LanguageEvent {
  LanguageChanged = '[Language Event] language changed'
}

export const INPUT_DEBOUNCE_TIME = 300

export enum SaleStatus {
  Fail = 'FAIL',
  Auth = 'AUTH',
  Sales = 'SALES',
  Void = 'VOID',
  NotSales = 'NOTSALES',
  Processing='PROCESSING'
}

