<script lang="ts">
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { filePath } from '@/utilities/file'
import { AppBase, StoreMixin } from '@/mixins'

/**
 * 画面仕様書
 *
 * 機能名　  : 共通機能
 * 画面ＩＤ  : MLC-0900
 * 画面名　  : （画面共通）
 * 概要　　  : メンテナンス中に表示される画面（管理コンソール画面にてメンテナンスモードへ切り替え）
 */
@Component
export default class MLC_0900 extends Mixins(AppBase, StoreMixin) {
  iconMaintenance = filePath.iconMaintenance

  constructor() {
    super()
    this.showPrimaryHeader({ withNotificationMessage: false, withBackButton: false })
  }
}
</script>

<template>
  <v-layout align-center column fill-height id="mlc-0900" justify-center>
    <img
      :src="iconMaintenance"
      alt=""
      class="d-block mx-auto"
      style="width: 20%; margin-bottom: 2.5rem;"
    />
    <div
      class="font-20 font-weight-bold mb-0"
      v-html="
        getContentByCurrentLanguage($store.getters['system/properties'].maintenance.message)
      "
    >
    </div>
  </v-layout>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
ul {
  text-align: center;
  padding: 0 24px;
}

::v-deep li {
  text-align: left;
}
</style>
