<script lang="ts">
import { Mixins, Component } from 'vue-property-decorator'
import { StoreMixin, AppBase } from '@/mixins'

@Component
export default class AppFooter extends Mixins(AppBase, StoreMixin) {
  constructor() {
    super()
  }

  get externalLink() {
    return this.$store.getters['system/link']
  }

  get showFooter() {
    // MLC-0900
    if (this.systemProperties.maintenance.isShow) {
      return false
    }
    // MTP-0400
    if (this.isWithdrawUser) {
      return false
    }
    // MLO-0110
    if (!this.userProfile.emailVerified) {
      return true
    }
    const isUserRestricted =
      this.isUserAuthenticated &&
      this.isUserPermissionDenied &&
      this.systemProperties.mobile.loginRestrictions

    // MLO-0900
    if (isUserRestricted) {
      return false
    }

    return true
  }

  handleFAQClick(): void {
    // https://developers.google.com/web/tools/lighthouse/audits/noopener
    const newWindow = window.open(this.getContentByCurrentLanguage(this.externalLink.faq))
    if (newWindow) {
      newWindow.opener = null
    }
  }

  handlePrivacyClick(): void {
    // https://developers.google.com/web/tools/lighthouse/audits/noopener
    const newWindow = window.open(this.getContentByCurrentLanguage(this.externalLink.privacy))
    if (newWindow) {
      newWindow.opener = null
    }
  }

  handleTermClick(): void {
    // https://developers.google.com/web/tools/lighthouse/audits/noopener
    const newWindow = window.open(this.getContentByCurrentLanguage(this.externalLink.term))
    if (newWindow) {
      newWindow.opener = null
    }
  }

  handletransactionClick(): void {
    // https://developers.google.com/web/tools/lighthouse/audits/noopener
    const newWindow = window.open(
      this.getContentByCurrentLanguage(this.externalLink.transaction)
    )
    if (newWindow) {
      newWindow.opener = null
    }
  }
}
</script>

<template>
  <v-footer absolute app padless v-if="showFooter">
    <v-container>
      <div class="footer-primary footer-row" no-gutters>
        <div @click="handleFAQClick" class="btn--text footer-3" v-ripple>
          {{ $t('Footer.001') }}
        </div>
        <div class="width-1px">
          <v-divider vertical />
        </div>
        <div @click="handlePrivacyClick" class="btn--text footer-3" v-ripple>
          {{ $t('Footer.002') }}
        </div>
        <div class="width-1px">
          <v-divider vertical />
        </div>
        <div @click="handleTermClick" class="btn--text footer-3" v-ripple>
          {{ $t('Footer.003') }}
        </div>
      </div>
      <div class="footer-primary footer-row mt-2" no-gutters>
        <div @click="handletransactionClick" class="btn--text footer-3" v-ripple>
          {{ $t('Footer.004') }}
        </div>
      </div>
    </v-container>
  </v-footer>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer-primary {
  font-size: 1.4rem;
  text-align: center;
  width: 100%;
}

.btn--text {
  cursor: pointer;
}

.small-col {
  width: 1px;
  flex-grow: unset;
}

.width-1px {
  width: 1px;
}

.footer {
  &-row {
    display: flex;
    flex-direction: row;
  }

  &-3 {
    flex: 2;
  }
}
</style>

