import { ILocationInformation } from '@/custommodels'
import { CURRENT_LOCATION, Regex } from './constant'
import i18n, { LanguageCode } from '@/i18n'

/**
 * @param currency
 * @description format a string for currency format
 * @example formatCurrency('123456789')
 * formatCurrency(123456789)
 * @returns {string} 123,456,789
 */
export function formatCurrency(currency: string | number): string {
  let text = `${currency}`
  return text.replace(Regex.CURRENCY_FORMAT, '$1,').trim()
}

/**
 * @description Work around for a bug on chrome iOS
 * v-select trigger softkeyboard when clicked
 * @example
 * mounted () {
 *    fixVSelectKeyboardForIOS()
 * }
 */
export function fixVSelectKeyboardForIOS(): void {
  const input: any = document.querySelector('.v-select__selections > input')
  if (input) {
    input.type = 'button'
  }
}

export function onPC(): boolean {
  // https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript
  if (isIPadOS()) {
    return false
  }

  if (isMacOS() || isWindowOS()) {
    return true
  }

  return false
}

export function base64ToUri(base64: string, mimeType: string): string {
  return base64 && base64 !== '' ? `data:image/${mimeType};base64,${base64}` : base64
}

function isMacOS() {
  return /MacIntel/.test(navigator.platform)
}

function isWindowOS() {
  return /Win32/.test(navigator.platform)
}

function isIPadOS() {
  return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && isMacOS()
}

export function compareString(a: string, b: string) {
  let minLength = a.length > b.length ? b.length : a.length

  for (let i = 0; i < minLength; i++) {
    if (a[i] > b[i]) {
      return 1
    } else if (a[i] < b[i]) {
      return -1
    }
  }

  if (a.length > b.length) {
    return 1
  } else if (a.length < b.length) {
    return -1
  }

  return 0
}

/*
 * location value following priority
 * 「候補地名」＞「住所」＞「緯度経度」
 * candidatePlaceName > streetAddress > longAndLat
 */
export function getLocationByPriority(locationInformation: ILocationInformation): string {
  if (!locationInformation) return ''
  if (locationInformation.candidatePlaceName) {
    return locationInformation.candidatePlaceName
  }
  if (locationInformation.streetAddress) {
    return locationInformation.streetAddress
  }
  if (locationInformation.longAndLat) {
    return locationInformation.longAndLat
  }

  return ''
}

// Check image url can be loaded
export function isImageLoaded(url: string = ''): Promise<boolean> {
  const image = new Image()

  return new Promise((res) => {
    image.onload = () => {
      res(true)
    }

    image.onerror = () => {
      res(false)
    }

    image.src = url
  })
}

export function getContentByCurrentLanguage(content: any) {
  try {
    const translatedContent = content[i18n.locale]
    return translatedContent ? translatedContent : content[LanguageCode.En]
  } catch (error) {
    return ''
  }
}

export function convertLngAndLatToString(lng: number | string, lat: number | string) {
  return lat + ',' + lng
}

export function getInfoDepartureByPriority(locationInformation: ILocationInformation): string {
  if (locationInformation) {
    if (locationInformation.candidatePlaceName) {
      return locationInformation.candidatePlaceName === CURRENT_LOCATION
        ? i18n.t('MRO-0000.003').toString()
        : locationInformation.candidatePlaceName
    }
    if (locationInformation.streetAddress) {
      return locationInformation.streetAddress
    }
  }
  return i18n.t('MRO-0100.003').toString()
}

export function getInfoArrivalByPriority(locationInformation: ILocationInformation): string {
  if (locationInformation) {
    if (locationInformation.candidatePlaceName) {
      return locationInformation.candidatePlaceName === CURRENT_LOCATION
        ? i18n.t('MRO-0000.003').toString()
        : locationInformation.candidatePlaceName
    }
    if (locationInformation.streetAddress) {
      return locationInformation.streetAddress
    }
  }
  return i18n.t('MRO-0100.004').toString()
}
