const language = 'language'
const qrQueryPersistent = 'qr-persistent'
const gateQrPersistent = 'gate-qr-persistent'
const addQrTicketPersistent = 'add-qr-ticket-persistent'
const editCreditCardQueryPersistent = 'edit-credit-card-persistent'
const walkingSpeed = 'walk'
const entID = 'entID'

export class StorageService {
  public static isQueryFromQr(): boolean {
    return !!this.getQueryFromQr()
  }

  public static isQueryFromGateQr(): boolean {
    return !!this.getQueryFromGateQr()
  }

  public static isQueryFromEditCreditCard(): boolean {
    return this.getQueryFromEditCreditCard() === editCreditCardQueryPersistent
  }

  public static isQueryFromAddQRTicket(): boolean {
    return !!this.getQueryFromAddQRTicket()
  }

  public static getCurrentLanguage(): string | null {
    return localStorage.getItem(language)
  }

  public static setCurrentLanguage(value: string): void {
    localStorage.setItem(language, value)
  }

  public static setQueryFromQr(value: string): void {
    sessionStorage.setItem(qrQueryPersistent, value)
  }

  public static getQueryFromQr(): string | null {
    return sessionStorage.getItem(qrQueryPersistent)
  }

  public static removeQueryFromQr(): void {
    sessionStorage.removeItem(qrQueryPersistent)
  }

  public static setQueryFromEditCreditCard(): void {
    localStorage.setItem(editCreditCardQueryPersistent, editCreditCardQueryPersistent)
  }

  private static getQueryFromEditCreditCard(): string | null {
    return localStorage.getItem(editCreditCardQueryPersistent)
  }

  public static removeQueryFromEditCreditCard(): void {
    localStorage.removeItem(editCreditCardQueryPersistent)
  }

  public static setQueryFromGateQr(value: string): void {
    sessionStorage.setItem(gateQrPersistent, value)
  }

  public static getQueryFromGateQr(): string | null {
    return sessionStorage.getItem(gateQrPersistent)
  }

  public static removeQueryFromGateQr(): void {
    sessionStorage.removeItem(gateQrPersistent)
  }

  public static setQueryFromAddQRTicket(value: string): void {
    sessionStorage.setItem(addQrTicketPersistent, value)
  }

  public static getQueryFromAddQRTicket(): string | null {
    return sessionStorage.getItem(addQrTicketPersistent)
  }

  public static removeQueryFromAddQRTicket(): void {
    sessionStorage.removeItem(addQrTicketPersistent)
  }

  public static getWalkingSpeedValue(): number | null {
    const walkingSpeedStr = localStorage.getItem(walkingSpeed)
    const walkingSpeedValue = Number(walkingSpeedStr)
    if (walkingSpeedStr && !Number.isNaN(walkingSpeedValue)) {
      return walkingSpeedValue
    } else {
      return null
    }
  }

  public static setWalkingSpeedValue(walkingSpeedVal: number): void {
    localStorage.setItem(walkingSpeed, walkingSpeedVal.toString())
  }

  public static setTransportConditionByKey(key: string, val: boolean) {
    val ? localStorage.setItem(key, '1') : localStorage.setItem(key, '0')
  }

  public static getTransportConditionByKey(key: string): boolean | null {
    const transportConditionStr = localStorage.getItem(key)
    if (transportConditionStr && transportConditionStr === '1') {
      return true
    }
    if (transportConditionStr && transportConditionStr === '0') {
      return false
    }

    return null
  }

  public static setEntID(value: string): void {
    localStorage.setItem(entID, value)
  }

  public static getEntID(): string | null {
    return localStorage.getItem(entID)
  }

  public static removeEntID(): void {
    localStorage.removeItem(entID)
  }
}
