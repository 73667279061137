var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',[(_vm.isLoadingFirestore)?[_c('splash-screen')]:[_c('app-header'),_c('v-content',[_c('v-container',{staticClass:"pt-0 pb-8",attrs:{"fill-height":"","fluid":"","id":"qr-body"}},[(
            _vm.$store.getters['system/properties'].notification.isShow &&
              _vm.$store.getters['layout/showNotificationMessage']
          )?_c('v-alert',{staticClass:"alert-container color-frame-header",attrs:{"dense":"","outlined":""}},[_c('div',{staticClass:"font-15",domProps:{"innerHTML":_vm._s(
              _vm.getContentByCurrentLanguage(
                _vm.$store.getters['system/properties'].notification.message
              )
            )}})]):_vm._e(),_c('v-fade-transition',[(_vm.systemProperties.maintenance.isShow)?_c('maintenance-screen'):(!_vm.isUserAuthenticated)?[(_vm.isWithdrawUser)?_c('WithdrawScreen'):_c('login-process')]:_c('router-view')],2)],1)],1),_c('app-footer'),_c('app-footer-menu'),_c('app-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],_c('app-dialog')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }