<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import { StoreMixin } from '@/mixins'
import { FooterMenuEvent, FooterMenuTab } from '@/utilities'
import { footerMenuEventBus } from '@/main'

@Component
export default class AppFooterMenu extends Mixins(StoreMixin) {
  constructor() {
    super()
  }

  get activatedTab(): number {
    const footerMenuValueRecord = {
      [FooterMenuTab.Top]: 0,
      [FooterMenuTab.RouteSearch]: 1,
      [FooterMenuTab.MyTicket]: 2,
    }
    return footerMenuValueRecord[this.currentFooterTab]
  }

  destroyed() {
    footerMenuEventBus.$off(FooterMenuEvent.ChangeTab)
  }

  get showFooter() {
    // MLC-0900
    if (this.systemProperties.maintenance.isShow) {
      return false
    }

    if (!this.isUserAuthenticated) {
      return false
    }

    if (this.userProfile.isNewUser) {
      return false
    }

    const isUserRestricted =
        this.isUserAuthenticated &&
        this.isUserPermissionDenied &&
        this.systemProperties.mobile.loginRestrictions

    // MLO-0900
    if (isUserRestricted) {
      return false
    }

    return true
  }

  handleTopClick(): void {
    if (this.currentFooterTab === FooterMenuTab.Top) {
      this.restartTopTabToDefaultProcess()
    } else {
      this.switchToTopTab()
    }
  }

  handleRouteSearchClick(): void {
    if (this.currentFooterTab === FooterMenuTab.RouteSearch) {
      this.restartRouteSearchTabToDefaultProcess()
    } else {
      this.switchToRouteSearchTab()
    }
  }

  handleMyTicketClick(): void {
    if (this.currentFooterTab === FooterMenuTab.MyTicket) {
      this.restartMyTicketTabToDefaultProcess()
    } else {
      this.switchToMyTicketTab()
    }
  }
}
</script>

<template>
  <v-bottom-navigation app grow fixed color="primary" class="footer-menu-bg-color" :value="activatedTab" v-if="showFooter">
    <v-btn @click="handleTopClick">
      <span>{{ $t('Fixedfooter.001') }}</span>
      <v-icon>{{ $icon.mdiHome }}</v-icon>
    </v-btn>
    <v-divider vertical v-show="isHaveRouterFunction" />
    <v-btn :disabled="!userProfile.emailVerified" v-show="isHaveRouterFunction" @click="handleRouteSearchClick">
      <span>{{ $t('Fixedfooter.002') }}</span>
      <v-icon>{{ $icon.mdiMapMarkerRadius }}</v-icon>
    </v-btn>
    <v-divider vertical />
    <v-btn :disabled="!userProfile.emailVerified" @click="handleMyTicketClick">
      <span>{{ $t('Fixedfooter.003') }}</span>
      <v-icon>{{ $icon.mdiTicketAccount }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.v-item-group.v-bottom-navigation .v-btn {
  font-size: 1.4rem;
}
</style>
