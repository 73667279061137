import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { ITicketMaster, IOrderMaster, IPriceMaster } from '@/models'
import { ITicketPurChasedModuleState, IRootState } from '@/store/utilities'
import ICompanyMaster from '@/models/CompanyMaster'
import {
  IQuantityCustom,
  IRelatedInfoTicket,
  IOptionGroupCustom,
  IPriceCustom,
  ITicketCustom,
} from '@/custommodels'

function findMainOrder(currentOrder: IOrderMaster, orders: IOrderMaster[]) {
  let mainOrder: IOrderMaster | undefined = currentOrder
  do {
    mainOrder = orders.find((_order) => _order.id === mainOrder!.parentTicketID)
    if (!mainOrder) return null
  } while (mainOrder.parentTicketID.length !== 0)

  return mainOrder
}

function createQuantityCustom(order: IOrderMaster, prices: IPriceMaster[]): IQuantityCustom[] {
  let identifiers = Object.keys(order.numbers)
  return prices
    .filter((price) => identifiers.includes(price.identifier))
    .map(
      (price): IQuantityCustom => {
        return {
          ...price,
          id: price.id,
          quantity: order.numbers[price.identifier],
        }
      }
    )
}

function createCompany(ticket: ITicketMaster, companies: ICompanyMaster[]): ICompanyMaster[] {
  let identifiers = Object.keys(ticket.priceInfo.companys)
  return companies
    .filter((company) => !company.isHide && identifiers.includes(company.id))
    .map((company) => {
      return { ...company }
    })
}

function mapOptionsWithPricesAndNumber(
  tickets: ITicketMaster[],
  orders: IOrderMaster[],
  prices: IPriceMaster[],
  companies: ICompanyMaster[]
) {
  return tickets.map((ticket) => {
    let _order = orders.find((_order) => _order.ticketID === ticket.id)!
    return {
      ...ticket,
      ..._order,
      canRefund: _order.canRefund,
      id: ticket.id,
      orderId: _order.orderID,
      idOrder: _order.id,
      localCompanies: createCompany(ticket, companies),
      localQuantity: createQuantityCustom(_order, prices),
    }
  })
}

function mapTicketWithPrice(
  ticket: ITicketCustom | ITicketMaster,
  prices: IPriceMaster[]
): IPriceCustom[] {
  return prices
    .filter((price: any) => Object.keys(ticket.priceInfo.prices).includes(price.identifier))
    .map((price) => {
      return {
        ...price,
        id: price.id,
        imageURL: price.iconURL,
        identifier: price.identifier,
        display: price.display,
        price: ticket.priceInfo.prices[price.identifier],
      }
    })
}

function mergeTicketWidthOrderAndPrice(
  ticket: ITicketMaster,
  order: IOrderMaster,
  childOrders: IOrderMaster[],
  prices: IPriceMaster[],
  companies: ICompanyMaster[],
  ticketOptions: ITicketMaster[]
): ITicketCustom {
  return {
    ...ticket,
    ...order,
    usages: order.usages || [],
    id: ticket.id,
    orderID: order.orderID,
    idOrder: order.id,
    localQuantity: createQuantityCustom(order, prices),
    localOptionsQuantity: mapOptionsWithPricesAndNumber(
      ticketOptions,
      childOrders,
      prices,
      companies
    ),
    localCompanies: createCompany(ticket, companies),
  }
}

function mapTicketWithOrder(
  ticket: ITicketMaster,
  order: IOrderMaster,
  companies: ICompanyMaster[],
  prices: IPriceMaster[]
): ITicketCustom {
  return {
    ...ticket,
    ...order,
    usages: order.usages || [],
    id: ticket.id,
    idOrder: order.id,
    localCompanies: createCompany(ticket, companies),
    localQuantity: createQuantityCustom(order, prices),
  }
}

function compareDateTime(a: Date, b: Date) {
  if (a < b) return -1
  if (a > b) return 1
  return 0
}

function getTicketMainAndChildForRefund(
  mainTicket: ITicketMaster,
  mainOrder: IOrderMaster,
  ordersUnuse: IOrderMaster[],
  packOrders: IOrderMaster[],
  ticketMaster: ITicketMaster[],
  pricesMater: IPriceMaster[],
  companiesMaster: ICompanyMaster[]
) {
  let childOrders = ordersUnuse.filter(
    (orderChild) => orderChild.parentTicketID === mainOrder.id
  )
  let childPack = packOrders.filter((packOrder) => packOrder.parentTicketID === mainOrder.id)
  childOrders = [...childOrders, ...childPack]
  let childTickets = ticketMaster.filter(
    (ticket) =>
      !ticket.basic.isSet &&
      childOrders.some((childOrder) => childOrder.ticketID === ticket.id)
  )
  let ticketIds = childTickets.map((ticket) => ticket.id)
  return {
    ticket: mapTicketWithOrder(mainTicket, mainOrder, companiesMaster, pricesMater),
    relatedTicket: ticketIds
      .map(
        (ticketId): ITicketCustom => {
          let order = childOrders.find((order) => order.ticketID === ticketId)!
          let ticket = ticketMaster.find((_ticket) => _ticket.id === ticketId)!
          return {
            ...mapTicketWithOrder(ticket, order, companiesMaster, pricesMater),
          }
        }
      )
      .sort((a, b) =>
        compareDateTime(a.purchaseDateTime.toDate(), b.purchaseDateTime.toDate())
      ),
  }
}

function getTicketOptionForRefund(
  ticketOption: ITicketMaster,
  orderOption: IOrderMaster,
  companiesMaster: ICompanyMaster[],
  pricesMaster: IPriceMaster[]
) {
  return {
    ticket: mapTicketWithOrder(ticketOption, orderOption, companiesMaster, pricesMaster),
    relatedTicket: [],
  }
}

function getParentTicketOfSetTicket(
  setOrder: IOrderMaster,
  ordersMaster: IOrderMaster[],
  packsOrder: IOrderMaster[],
  ticketsMaster: ITicketMaster[],
  companiesMaster: ICompanyMaster[],
  pricesMaster: IPriceMaster[]
) {
  let parentOrder = [...ordersMaster, ...packsOrder].find(
    (order) => order.id === setOrder.parentTicketID
  )!
  if (!parentOrder) return null
  let parentTicket = ticketsMaster.find((_ticket) => _ticket.id === parentOrder.ticketID)!
  if (!parentTicket) return null
  if (parentOrder.parentTicketID.length === 0) {
    let mainOfChildOrder = [...ordersMaster, ...packsOrder].filter(
      (_order) => _order.parentTicketID === parentOrder.id
    )
    let mainOfChildTicket = ticketsMaster.filter((_ticket) =>
      mainOfChildOrder.some((_order) => _order.ticketID === _ticket.id && !_ticket.basic.isSet)
    )
    let mainOfChildTicketCustom = (mainOfChildOrder
      .map((_order) => {
        let ticket = mainOfChildTicket.find((_ticket) => _ticket.id === _order.ticketID)!
        if (!ticket) return null
        return mapTicketWithOrder(ticket, _order, companiesMaster, pricesMaster)
      })
      .filter((_ticket) => _ticket !== null) as ITicketCustom[]).sort((a, b) =>
        compareDateTime(a.purchaseDateTime.toDate(), b.purchaseDateTime.toDate())
      )
    return {
      ticket: mapTicketWithOrder(parentTicket, parentOrder, companiesMaster, pricesMaster),
      relatedTicket: mainOfChildTicketCustom,
    }
  }
  return {
    ticket: mapTicketWithOrder(parentTicket, parentOrder, companiesMaster, pricesMaster),
    relatedTicket: [],
  }
}

function getTicketAndRelatedTicketForMainCase(
  mainOrder: IOrderMaster,
  mainTicket: ITicketMaster,
  packOrders: IOrderMaster[],
  tickets: ITicketMaster[],
  companies: ICompanyMaster[],
  prices: IPriceMaster[],
  orders: IOrderMaster[]
): IRelatedInfoTicket | null {
  let ticket = mapTicketWithOrder(mainTicket, mainOrder, companies, prices)
  let orderList = [...orders, ...packOrders]
  let relationOrders = orderList.filter(
    (_order) => _order.parentTicketID === mainOrder.id
  )
  let reLationOrdersRemovedPack = relationOrders.filter((_order) => !_order.isHide)
  let relatedOfChildrenOrders = orders.filter((_order) =>
    relationOrders.some((relatedOrder) => relatedOrder.id === _order.parentTicketID)
  )
  let relationOrdersToAdded = [...reLationOrdersRemovedPack, ...relatedOfChildrenOrders]
  let relationTickets = tickets.filter((_ticket) =>
    relationOrdersToAdded.some((_order) => _ticket.id === _order.ticketID)
  )
  let relationTicketMerged = relationOrdersToAdded.map((relationOrder) => {
    let _relationOrder = relationTickets.find(
      (_ticket) => relationOrder.ticketID === _ticket.id
    )!
    return mapTicketWithOrder(_relationOrder, relationOrder, companies, prices)
  })

  let orderSortId: any = []
  let subTickets = tickets.filter((_ticket) =>
    orderList.some((_order) => _ticket.id === _order.ticketID)
  ).reduce(function (map: any, _ticket: any) {
    map[_ticket.id] = _ticket;
    return map;
  }, {});
  let ordersForSort = orderList.map((a) => {
    let _ticket = subTickets[a.ticketID]
    let sortKey = ""
    let priority = 0
    if (_ticket) {
      sortKey = _ticket.sortKey;
      if (_ticket.basic.canMain && (!a.parentTicketID || a.parentTicketID == "")) {
        priority = 0;
      } else if (_ticket.basic.isSet) {
        priority = 1
      } else if (_ticket.basic.canOption) {
        priority = 2
      }
    }
    return {
      id: a.id,
      ticketID: a.ticketID,
      parent: a.parentTicketID,
      purchaseDateTime: a.purchaseDateTime,
      sortKey: sortKey,
      priority: priority
    }
  });

  let mapOrderSub: any = {}
  do {
    let _parentId = "";
    let _orderSubs: any = [];
    if (orderSortId && orderSortId.length > 0) {
      _parentId = orderSortId.pop();
    }
    for (let index = ordersForSort.length - 1; index >= 0; index--) {
      const _order = ordersForSort[index];
      if (_order.parent === _parentId) {
        _orderSubs.push(_order)
        orderSortId.push(_order.id)
        ordersForSort.splice(index, 1)
      }
    }
    _orderSubs = _orderSubs.sort((a: any, b: any) => {
      let priority = a.priority - b.priority;
      if (priority != 0) {
        return priority;
      } else {
        let result = compareDateTime(b.purchaseDateTime.toDate(), a.purchaseDateTime.toDate())
        if (result != 0) {
          return result
        } else {
          return a.sortKey.localeCompare(b.sortKey)
        }
      }
    })
    if (_orderSubs && _orderSubs.length > 0) {
      mapOrderSub[_parentId] = _orderSubs;
    }
  } while (orderSortId && orderSortId.length > 0)
  let mergeList: any = mapOrderSub[""];
  for (let index = 0; index < mergeList.length; index++) {
    const _element = mergeList[index];
    const _orderSubs = mapOrderSub[_element.id];
    if (_orderSubs) {
      mergeList.splice(index + 1, 0, ..._orderSubs);
    }
  }

  let finalListTemp: any = {};
  mergeList.forEach((element: any, index: any) => {
    finalListTemp[element.id] = index;
  });
  return {
    ticket: ticket,
    relatedTicket: relationTicketMerged.sort((a, b) =>
      finalListTemp[a.idOrder] - finalListTemp[b.idOrder]
    ),
  }
}

const state: ITicketPurChasedModuleState = {
  currentOrderId: '',
  optionOrderIds: [],
  saleIdResponse: '',
  ticketIdResponse: '',
  orderIdForRefund: '',
}

const mutations: MutationTree<ITicketPurChasedModuleState> = {
  SET_CURRENT_ORDER: (state, orderId: string) => {
    state.currentOrderId = orderId
  },
  SET_ORDERID_FOR_REFUND: (state, orderId: string) => {
    state.orderIdForRefund = orderId
  },
  SET_ORDER_OPTION: (state, orderOptionIds: string[]) => {
    state.optionOrderIds = orderOptionIds
  },
  SET_ORDER_ID_RESPONSE: (state, saleIdResponse: string) => {
    state.saleIdResponse = saleIdResponse
  },
  SET_TICKET_ID_RESPONSE: (state, ticketIdResponse: string) => {
    state.ticketIdResponse = ticketIdResponse
  },
}

const actions: ActionTree<ITicketPurChasedModuleState, IRootState> = {
  setCurrentTicket: ({ commit }, orderId: string) => {
    commit('SET_CURRENT_ORDER', orderId)
  },
  setOrderOptionIds: ({ commit, rootState, state }, orderOptionIds: string[]) => {
    commit('SET_ORDER_OPTION', orderOptionIds)
  },
  setOrderIdResponse: ({ commit }, saleIdResponse: string) => {
    commit('SET_ORDER_ID_RESPONSE', saleIdResponse)
  },
  setTicketIdResponse: ({ commit }, ticketIdResponse) => {
    commit('SET_TICKET_ID_RESPONSE', ticketIdResponse)
  },
  setOrderIdForRefund: ({ commit }, orderId) => {
    commit('SET_ORDERID_FOR_REFUND', orderId)
  },
}

const getters: GetterTree<ITicketPurChasedModuleState, IRootState> = {
  getOrdersUsing: (state, getters, { database }): IOrderMaster[] => {
    return database.ordersUsing.filter((order) => !order.isHide)
  },
  getOrdersUnuse: (state, getters, { database }): IOrderMaster[] => {
    return database.ordersUnuse.filter((order) => !order.isHide)
  },
  getOrdersUsed: (state, getters, { database }): IOrderMaster[] => {
    return database.ordersUsed.filter((order) => !order.isHide)
  },
  getOrders: (state, getters): IOrderMaster[] => {
    return [...getters.getOrdersUnuse, ...getters.getOrdersUsing, ...getters.getOrdersUsed]
  },
  ticketsPurchased: (state, getters, { database }) => {
    let tickets = database.ticketMaster
    let orders = [...getters.getOrdersUsing, ...getters.getOrdersUnuse]
    let mainOrders = orders.filter(
      (order: IOrderMaster) => order.parentTicketID.length === 0 && order.canBuyOption
    )
    let ticketPurchased = mainOrders.map((mainOrder: IOrderMaster) => {
      let ticket = tickets.find((ticket) => ticket.id === mainOrder.ticketID)!
      let childOrders = getters.getOrders.filter(
        (_order: IOrderMaster) => _order.parentTicketID === mainOrder.id
      )
      let _ticketOptions = tickets.filter(
        (option) =>
          childOrders.some((order: IOrderMaster) => order.ticketID === option.id) &&
          !option.basic.isSet
      )
      return mergeTicketWidthOrderAndPrice(
        ticket,
        mainOrder,
        childOrders,
        database.priceMaster,
        database.companyMaster,
        _ticketOptions
      )
    })

    return ticketPurchased
    // .filter((ticket: any) => ticket.options.length !== 0)
  },
  currentTicketPurchased: (state, getters, { database }) => {
    let mainTicket = (getters.ticketsPurchased as ITicketCustom[]).find(
      (ticket: ITicketCustom) => ticket.orderID === state.currentOrderId
    )!
    if (!mainTicket) return null
    let childOrders = [
      ...(getters.getOrders as IOrderMaster[]).filter(
        (order: IOrderMaster) => order.parentTicketID === mainTicket.idOrder
      ),
      ...database.packOrders.filter((_order) => _order.parentTicketID === mainTicket.idOrder),
      ...database.packOrderUsing.filter(
        (_order) => _order.parentTicketID === mainTicket.idOrder
      ),
    ]
    let childOrderIds = childOrders.map((order) => order.ticketID)
    let _ticketOptions = database.ticketMaster.filter(
      (option: ITicketMaster) => childOrderIds.includes(option.id) && !option.basic.isSet
    )
    return {
      ...mergeTicketWidthOrderAndPrice(
        mainTicket,
        mainTicket,
        childOrders,
        database.priceMaster,
        database.companyMaster,
        _ticketOptions
      ),
      idOrder: mainTicket.orderID,
      purchasedTicketId: mainTicket.idOrder,
      localPrices: mapTicketWithPrice(mainTicket, database.priceMaster),
    }
  },
  localOptionGroups: (state, getters, { database }): IOptionGroupCustom[] | null => {
    if (!getters.currentTicketPurchased) {
      return null
    }
    return database.optionMaster
      .filter(
        (optionGroup) =>
          getters.currentTicketPurchased!.options.includes(optionGroup.id) &&
          optionGroup.canBuy
      )
      .map(
        (optionGroup): IOptionGroupCustom => {
          let ticketOptions = database.ticketMaster.filter(
            (ticketOption) =>
              optionGroup.tickets.includes(ticketOption.id) &&
              ticketOption.basic.canOption &&
              ticketOption.canBuy
          )
          return {
            ...optionGroup,
            id: optionGroup.id,
            localTickets: ticketOptions.map((ticketOption) => {
              return {
                ...ticketOption,
                id: ticketOption.id,
                localPrices: mapTicketWithPrice(ticketOption, database.priceMaster),
              }
            }) as ITicketCustom[],
          }
        }
      )
  },
  ticketAndRelatedInfor: (state, getters, { database }): IRelatedInfoTicket | null => {
    let thisOrder = getters.getOrders.find(
      (_order: IOrderMaster) => _order.id === state.ticketIdResponse
    )! as IOrderMaster
    if (!thisOrder) return null
    let thisTicket = database.ticketMaster.find((ticket) => ticket.id === thisOrder.ticketID)!
    if (!thisTicket) return null
    if (thisOrder.parentTicketID.length === 0) {
      // get mainticket and it of sets and options
      return getTicketAndRelatedTicketForMainCase(
        thisOrder,
        thisTicket,
        [...database.packOrders, ...database.packOrderUsing, ...database.packOrderUsed],
        database.ticketMaster,
        database.companyMaster,
        database.priceMaster,
        getters.getOrders as IOrderMaster[]
      )
    } else if (thisTicket) {
      let mainOrder = findMainOrder(thisOrder, [
        ...(getters.getOrders as IOrderMaster[]),
        ...database.packOrders,
        ...database.packOrderUsing,
        ...database.packOrderUsed
      ])
      if (!mainOrder) return null
      let mainTicket = database.ticketMaster.find(
        (_ticket) => _ticket.id === mainOrder!.ticketID
      )!
      if (!mainOrder) return null
      let mainAndRelated = getTicketAndRelatedTicketForMainCase(
        mainOrder,
        mainTicket,
        [...database.packOrders, ...database.packOrderUsing, ...database.packOrderUsed],
        database.ticketMaster,
        database.companyMaster,
        database.priceMaster,
        getters.getOrders as IOrderMaster[]
      )
      let thisTicketOfRelated = [
        ...mainAndRelated!.relatedTicket!.filter(
          (_ticket) => _ticket.idOrder !== thisOrder.id
        ),
      ]
      if (!mainAndRelated!.ticket.isHide) {
        thisTicketOfRelated = [mainAndRelated!.ticket, ...thisTicketOfRelated]
      }
      return {
        ticket: mapTicketWithOrder(
          thisTicket,
          thisOrder,
          database.companyMaster,
          database.priceMaster
        ),
        relatedTicket: thisTicketOfRelated
      }
    }
    return null
  },
  orderQuantity: (state, getters, { database }) => {
    let infoOrder = {
      main: {},
      options: [] as object[],
    }
    let orders = [...getters.getOrdersUsing, ...getters.getOrdersUnuse]
    let mainOrder = orders.find(
      (order) => order.orderID === state.currentOrderId && order.parentTicketID === ''
    )!

    let optionOrders: IOrderMaster[] = []

    if (!mainOrder) return null

    optionOrders = [
      ...database.ordersUnuse.filter((order) => order.parentTicketID === mainOrder.id),
      ...database.ordersUsing.filter((order) => order.parentTicketID === mainOrder.id),
      ...database.packOrders.filter((order) => order.parentTicketID === mainOrder.id),
      ...database.packOrderUsing.filter((order) => order.parentTicketID === mainOrder.id),
    ]

    let mainTicket = database.ticketMaster.find((ticket) => ticket.id === mainOrder.ticketID)
    if (!mainTicket) return null

    infoOrder.main = {
      id: mainTicket.id,
      numbers: { ...mainOrder.numbers },
    }

    let optionTicketIds = optionOrders.map((order) => order.ticketID)

    let optionTickets = database.ticketMaster.filter(
      (ticket) => !ticket.basic.isSet && optionTicketIds.includes(ticket.id)
    )

    optionTickets.forEach((ticket) => {
      let ticketQuantity = { id: ticket.id, numbers: {} }
      let numbers = optionOrders
        .filter((order) => order.ticketID === ticket.id)
        .map((order) => order.numbers)
      numbers.forEach((number: any) => {
        let keys = Object.keys(number)
        keys.forEach((key) => {
          let _numbers = ticketQuantity.numbers as any
          if (_numbers[key] === undefined || _numbers[key] === null) {
            _numbers[key] = 0
          }
          _numbers[key] += number[key]
        })
      })
      infoOrder.options.push(ticketQuantity)
    })

    return infoOrder
  },
  getSale: (state, getters, { database }) => database.saleMaster,
  currentOrderId: (state) => state.currentOrderId,
  saleIdResponse: (state) => state.saleIdResponse,
  getTicketForRefund: (state, getters, { database }): IRelatedInfoTicket | null => {
    let ordersUnuse = getters.getOrdersUnuse as IOrderMaster[]
    let ordersUsing = getters.getOrdersUsing as IOrderMaster[]
    let orders = [...ordersUnuse, ...ordersUsing];
    let currentOrder = orders.find((order) => order.id === state.orderIdForRefund)!
    if (!currentOrder) return null
    let currentTicket = database.ticketMaster.find(
      (ticket) => ticket.id === currentOrder.ticketID
    )!

    if (!currentTicket) return null
    if (currentOrder.parentTicketID.length === 0) {
      return getTicketMainAndChildForRefund(
        currentTicket,
        currentOrder,
        orders,
        database.packOrders,
        database.ticketMaster,
        database.priceMaster,
        database.companyMaster
      )
    } else if (currentTicket.basic.canOption) {
      let relatedOrder = orders.find(
        (order) =>
          order.parentTicketID === currentOrder.parentTicketID &&
          order.ticketID === currentOrder.ticketID
      )!
      return getTicketOptionForRefund(
        currentTicket,
        relatedOrder,
        database.companyMaster,
        database.priceMaster
      )
    } else if (currentTicket.basic.isSet) {
      return getParentTicketOfSetTicket(
        currentOrder,
        orders,
        database.packOrders,
        database.ticketMaster,
        database.companyMaster,
        database.priceMaster
      )
    }
    return null
  },
}

const ticketPurchasedModule: Module<ITicketPurChasedModuleState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default ticketPurchasedModule
