import Vue from 'vue'
import Router from 'vue-router'

import routes from './routes'
import store from '@/store'
import { StorageService } from '@/services'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (routeTo, routeFrom, next) => {
  if (routeTo.params['id']) {
    await store.dispatch('ticketPurchased/setOrderIdResponse', routeTo.params['id'])
  }

  if (routeTo.params['facilityId']) {
    StorageService.setQueryFromQr(routeTo.params['facilityId'])
  }

  if (routeTo.params['gateId']) {
    StorageService.setQueryFromGateQr(routeTo.params['gateId'])
  }

  if (routeTo.params['addQRTicketId']) {
    StorageService.setQueryFromAddQRTicket(routeTo.params['addQRTicketId'])
  }

  if (store.getters['auth/isAuthenticated']) {
    next({ name: 'RootView' })
  } else {
    next()
  }
})

export default router
